import { Observable, Observer } from 'rxjs';

import {
  CompaniesQueryParams,
  Company,
  AssociatedContact,
  CompanyNotes,
  CompanyTasks,
  CompanyCustomFields,
  AssociatedCompanies,
  AssociatedCompany,
  ImportCompaniesCSV
} from '../Models/company';

import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { CompanyImageUpdatResponseDto } from '@/app/Data/DTO/companyImageUpdatResponseDto';

import {
  AddPeopleCompanyDto,
  CreateCompanyDto,
  AssociatedContactToCompanyDto,
  RemoveAssociatedContactToCompanyDto,
  UpdateAssociatedContactRelationshipTypeDto,
  CreateCompanyNoteDto,
  DeleteCompanyNoteDto,
  DeleteCompanyCustomFieldDto,
  AddCompanyCustomFieldsDto,
  AssociateCompanyToCompanyDto,
  DeleteCompanyAssociationDto
} from '@/app/Data/DTO/companyDto';

import { Deal } from '../Models/deal';
import { IActivity } from '@/app/Data/services/Activity/activity.service';

export abstract class ICompanyRepository {
  abstract getCompanyById(companyId: string): Observable<Company>;
  abstract getLocalCompany(companyId: string): Observable<Company | undefined>;
  abstract saveCompanyLocal(company: Company): void;
  abstract createCompany(data: CreateCompanyDto): Observable<Company>;
  abstract editCompany(companyId: string, data: CreateCompanyDto): Observable<Company>;
  abstract deleteCompany(companyIds: string[]): Observable<SuccessResponse>;
  abstract deleteLocalCompany(companyId: string): void;
  abstract uploadCompanyLogo(data: FormData, companyId: string, progress$: Observer<any>): Observable<CompanyImageUpdatResponseDto>;
  abstract deleteCompanyLogo(companyId: string): Observable<CompanyImageUpdatResponseDto>;
  abstract getCompanies(params: CompaniesQueryParams): Observable<{ companies: Company[], total_count: number }>;
  abstract saveCompanies(companys: Company[], params: CompaniesQueryParams): void;
  abstract getLocalCompanies(): Observable<Company[]>;
  abstract updateLocalCompanyDetails(companyId: string, details: any): void
  abstract associateContactToCompany(data: AddPeopleCompanyDto[]): Observable<AddPeopleCompanyDto[]>
  abstract getAssociatedContactsToCompany(params: CompaniesQueryParams): Observable<AssociatedContactToCompanyDto>
  abstract getAssociatedContactsToCompanyLocal(companyId: string): Observable<AssociatedContact[] | undefined>
  abstract removeAssociatedContactToCompanyDto(data: RemoveAssociatedContactToCompanyDto): Observable<SuccessResponse>
  abstract updateAssociatedContactRelationshipType(data: UpdateAssociatedContactRelationshipTypeDto): Observable<SuccessResponse>
  abstract createCompanyNote(data: CreateCompanyNoteDto): Observable<CompanyNotes>
  abstract updateCompanyNote(data: CreateCompanyNoteDto): Observable<CompanyNotes>
  abstract deleteCompanyNote(data: DeleteCompanyNoteDto): Observable<CompanyNotes>
  abstract getCompanyNotes(params: CompaniesQueryParams): Observable<CompanyNotes[]>
  abstract getAssociatedCompanyNotesLocal(companyId: string): Observable<CompanyNotes[] | undefined>
  abstract getCompanyTasks(params: CompaniesQueryParams): Observable<CompanyTasks[]>
  abstract getAssociatedCompanyTasksLocal(companyId: string): Observable<CompanyTasks[] | undefined>
  abstract getCompanyCustomFields(params: CompaniesQueryParams): Observable<CompanyCustomFields[]>
  abstract getAssociatedCompanyCustomFieldsLocal(companyId: string): Observable<CompanyCustomFields[] | undefined>
  abstract deleteCompanyCustomFields(data: DeleteCompanyCustomFieldDto): Observable<CompanyCustomFields>
  abstract createCompanyCustomFields(data: AddCompanyCustomFieldsDto[]): Observable<CompanyCustomFields>
  abstract updateCompanyCustomFields(data: AddCompanyCustomFieldsDto[]): Observable<CompanyCustomFields>
  abstract associateCompanyToCompany(data: AssociateCompanyToCompanyDto[]): Observable<AssociateCompanyToCompanyDto[]>
  abstract getAssociatedCompanyToCompany(params: CompaniesQueryParams): Observable<AssociatedCompanies>
  abstract getAssociatedCompanyToCompanyLocal(companyId: string): Observable<AssociatedCompany[] | undefined>
  abstract removeAssociatedCompanyToCompany(data: DeleteCompanyAssociationDto): Observable<SuccessResponse>
  abstract updateAssociatedCompanyAssociationType(data: AssociateCompanyToCompanyDto): Observable<SuccessResponse>
  abstract getAssociatedDealsToCompany(params: CompaniesQueryParams): Observable<Deal[]>
  abstract getAssociatedDealsToCompanyLocal(companyId: string): Observable<Deal[] | undefined>
  abstract importCompaniesViaCSV(data: FormData, redis_id: string): Observable<ImportCompaniesCSV>
  abstract getCompanyActivityLocal(companyId: string): Observable<IActivity[] | undefined>
} 

import { CountryCode, format, isValidNumberForRegion, parsePhoneNumber } from "libphonenumber-js";
import { FileModel, FileType } from "../core/Models/file";
import { DomSanitizer } from "@angular/platform-browser";

// (?![^<]*>) avoid matching inside html tags
// (?![^>]*<\/) avoid matching between html tags
const html_tags = /(?=>[^>]*<\/)/gm
const pin_regex = /(?![^<]*>)(?![^>]*<\/)\b(\d{4,6})\b/gm
const us_phone_regex = /(?![^>]*<\/)((\B\([0-9]{3}\)|\b[0-9]{3})\s?[0-9]{3}-[0-9]{4}\b)/gm
const http_url_regex = /(?![^<]*>)(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))/ig
const break_line_regex = /(?:\r\n|\r|\n)/g


export const extractCountryCode = (phone: string, countryCode?: CountryCode): CountryCode | undefined => {
    return parsePhoneNumber(phone, countryCode)?.country
}

export const checkPhoneValidity = (phone: string, countryCode: CountryCode): boolean => {
    try {
        const code = extractCountryCode(phone, countryCode) ?? countryCode;
        return isValidNumberForRegion(phone, code)
    } catch { return false }
}

export const getFormattedPhoneNumber = (phone: string, countryCode: CountryCode): string => {
    const code = extractCountryCode(phone, countryCode) ?? countryCode;
    return format(phone, code, 'E.164')
}

export const formatMessageContent = (text: string): string => {

    return html_tags.test(text)
        ? text
        : text
            .replace(http_url_regex, '<a rel="nofollow" target="_blank" class="text-primary" href="$1">$1</a>')
            .replace(us_phone_regex, '<span class="phone-number text-primary">$1</span>')
            .replace(pin_regex, '<span class="pin-code text-primary">$1</span>')
            .replace(break_line_regex, '<br>')
}

export const DtoToFormData = (data: any): FormData => {
    const formData = new FormData();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const value: Array<any> = Array.isArray(data[key]) ? data[key] : [data[key]];
            for (const item of value) {
                item instanceof File || item instanceof Blob
                    ? formData.append(key, item)
                    : typeof item === 'object'
                        ? formData.append(key, JSON.stringify(item))
                        : formData.append(key, item);

            }
        }
    }
    return formData;
}

/**
 *
 * @param queryString example name=john&email=johndoe@mail.com
 * @returns
 */
export const extractQueryParams = (queryString: string): Record<string, string> => {
    const queryParams: Record<string, string> = {};
    const pairs = queryString.split('&');

    for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[key] = decodeURIComponent(value);
    }

    return queryParams;
}

export const processFiles = (files: File[], domSanitizer?: DomSanitizer) => {
    const promises = files.map(file =>
        new Promise<FileModel>((res, rej) => {
            if (domSanitizer && file.type.includes('video')) {
                try {
                    const model: FileModel = {
                        file,
                        type: 'video',
                        name: file.name,
                        url: domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string,
                    }
                    res(model)
                } catch (err) {
                    rej()
                }
            }
            if (domSanitizer && file.type.includes('audio')) {
                try {
                    const model: FileModel = {
                        file,
                        type: 'audio' as any,
                        name: file.name,
                        url: domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)) as string,
                    }
                    res(model)
                } catch (err) {
                    rej()
                }
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    res({
                        file,
                        name: file.name,
                        url: reader.result as string,
                        type: file.type.includes('image') ? 'image' : file.type.includes('video') ? 'video' : file.type.includes('audio') ? 'audio' as any : 'file',
                    })
                };
                reader.readAsDataURL(file);
            }
        })
    );
    return Promise.all(promises)
}

export const resizeImage = async (data: { MAX_WIDTH: number, MAX_HEIGHT: number, file: File, MIME_TYPE?: string }): Promise<File> => {
    // ex: for avatars call this fun with 150px MAX_WIDTH & MAX_HEIGHT
    if (data.file.type.includes('gif')) return data.file;
    const calculateSize = (img: HTMLImageElement, maxWidth: number, maxHeight: number) => {
        let width = img.width;
        let height = img.height;
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }
    const { MAX_WIDTH, MAX_HEIGHT, MIME_TYPE, file } = data
    const file_type = file.type
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    return new Promise<File>((res, rej) => {
        img.onerror = () => {
            URL.revokeObjectURL(img.src);
            rej("Cannot load image")
        };
        img.onload = (e) => {
            URL.revokeObjectURL(img.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
                (blob) => {
                    res(new File([blob as Blob], file.name ?? new Date().getTime().toString(), { type: file_type }))
                },
                MIME_TYPE || ['image/jpeg', 'image/png'].includes(file.type) ? file_type : 'image/jpeg', 0.9
            );
        };
    })
}

export const fileTypeFromName = (name?: string): FileType => {
    return !name ? 'file'
        : name.startsWith('image') || name.match(/\.(jpeg|jpg|gif|png|webp)$/) != null ? 'image'
            : name.includes('pdf') || name.match(/\.(pdf)$/) != null ? 'pdf'
                : name.startsWith('audio') || name.match(/\.(mp3|webm|wav|ogg|aac)$/) != null ? 'audio' as any
                    : name.startsWith('video') || name.match(/\.(mp4|flv|avi|mkv|mov)$/) != null ? 'video'
                        : 'file'
}

export const dynamicKeySort = (sortKey: string, direction: 'asc' | 'desc' = 'asc'): any => {
    const sortIndexOrder = direction === 'asc' ? 1 : -1;
    return (inner: any, outer: any) => {
        let innerValue = inner[sortKey];
        let outerValue = outer[sortKey];
        if (typeof innerValue === "string") {
            innerValue = ("" + innerValue).toLowerCase();
            outerValue = ("" + outerValue).toLowerCase();
        }
        const num = (innerValue < outerValue) ? -1 : (innerValue > outerValue) ? 1 : 0;
        return num * sortIndexOrder;
    }
}

export const bufferToWave = (audiobuffer: AudioBuffer, len: number) => {
    let numOfChan = audiobuffer.numberOfChannels, // let's say 2 channels
        length = len * numOfChan * 2 + 44,        // * 2: Each sample is 2 bytes (16 bits) | 44 => header each 32 is 4bytes and 16 is two bytes => 9 * 4 + 4 * 2 = 44
        buffer = new ArrayBuffer(length),
        view = new DataView(buffer),
        channels: Float32Array[] = [],
        i, sample,
        offset = 0,
        pos = 0;

    // write WAVE header
    setUint32(0x46464952);                         // "RIFF"
    setUint32(length - 8);                         // file length - 8
    setUint32(0x45564157);                         // "WAVE"

    setUint32(0x20746d66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(audiobuffer.sampleRate);
    setUint32(audiobuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit (hardcoded in this demo)

    setUint32(0x61746164);
    setUint32(length - pos - 8);                   // chunk length

    // write interleaved data
    for (i = 0; i < audiobuffer.numberOfChannels; i++)
        channels.push(audiobuffer.getChannelData(i));

    while (pos < length) {
        for (i = 0; i < numOfChan; i++) {             // interleave channels
            sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
            sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
            view.setInt16(pos, sample, true);          // write 16-bit sample
            pos += 2;
        }
        offset++                                     // next source sample
    }

    // create Blob
    return new Blob([buffer], { type: "audio/wav" });

    function setUint16(data: number) {
        view.setUint16(pos, data, true);
        pos += 2;
    }

    function setUint32(data: number) {
        view.setUint32(pos, data, true);
        pos += 4;
    }
}

export const formatMentionContent = (value: string): string => {
    let addBreak = /(.*)(\r\n|\r|\n)/.test(value);
    value = value.replace(/<[^(>|(\r\n|\r|\n)]*>/g, '').replace(/</g, '&lt;').replace(/</g, '&lt;');
    value = value.replace(/(\r\n|\r|\n)/g, '</br>');
    value = value.replace(/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))/ig, '<a class="link">$1</a>');
    value = value.replace(/(#[A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff_&]*)/ig, '<span class="hashtag">$1</span>');
    value = value.replace(/(^|\s)@([a-z0-9][a-z0-9_&]*)/ig, (match, p1, p2) => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(`${p2}`)) {
            return match;
        }
        return `${p1}<span class="mention">@${p2}</span>`;
    });
    value = value.replace(/(\[contact: ([\w\s]+)\])/ig, '<span class="contact-field">$1</span>');
    addBreak && (value = value + '<br>');
    return value;
}

export const getCompanyTypes = (): Array<{ name: string, value: string }> => {
    return [
        {
            name: 'Partner',
            value: 'Partner'
        },
        {
            name: 'Prospect',
            value: 'Prospect'
        },

        {
            name: 'Reseller',
            value: 'Reseller'
        },
        {
            name: 'Vendor',
            value: 'Vendor'
        },
        {
            name: 'Other',
            value: 'Other'
        },
    ];
}

export const getIndustryTypes = (): Array<{ name: string, value: string }> => {
    return [
        {
            name: 'Automobiles & Components',
            value: 'Automobiles & Components'
        },
        {
            name: 'Banks',
            value: 'Banks'
        },
        {
            name: 'Capital Goods',
            value: 'Capital Goods'
        },
        {
            name: 'Commercial & Professional Services',
            value: 'Commercial & Professional Services'
        },
        {
            name: 'Consumer Discretionary Distribution & Retail',
            value: 'Consumer Discretionary Distribution & Retail'
        },
        {
            name: 'Consumer Durables & Apparel',
            value: 'Consumer Durables & Apparel'
        },
        {
            name: 'Consumer Services',
            value: 'Consumer Services'
        },
        {
            name: 'Consumer Staples Distribution & Retail',
            value: 'Consumer Staples Distribution & Retail'
        },
        {
            name: 'Energy',
            value: 'Energy'
        },
        {
            name: 'Equity Real Estate Investment Trusts',
            value: 'Equity Real Estate Investment Trusts'
        },
        {
            name: 'Financial Services',
            value: 'Financial Services'
        },
        {
            name: 'Food, Beverage & Tobacco',
            value: 'Food, Beverage & Tobacco'
        },
        {
            name: 'Health Care Equipment & Services',
            value: 'Health Care Equipment & Services'
        },
        {
            name: 'Household & Personal Products',
            value: 'Household & Personal Products'
        },
        {
            name: 'Insurance',
            value: 'Insurance'
        },
        {
            name: 'Materials',
            value: 'Materials'
        },
        {
            name: 'Media & Entertainment',
            value: 'Media & Entertainment'
        },
        {
            name: 'Partner',
            value: 'Partner'
        },
        {
            name: 'Pharmaceuticals, Biotechnology & Life Sciences',
            value: 'Pharmaceuticals, Biotechnology & Life Sciences'
        },
        {
            name: 'Prospect',
            value: 'Prospect'
        },
        {
            name: 'Real Estate Management & Development',
            value: 'Real Estate Management & Development'
        },
        {
            name: 'Reseller',
            value: 'Reseller'
        },
        {
            name: 'Semiconductors & Semiconductor Equipment',
            value: 'Semiconductors & Semiconductor Equipment'
        },
        {
            name: 'Software & Services',
            value: 'Software & Services'
        },
        {
            name: 'Technology Hardware & Equipment',
            value: 'Technology Hardware & Equipment'
        },
        {
            name: 'Telecommunication Services',
            value: 'Telecommunication Services'
        },
        {
            name: 'Transportation',
            value: 'Transportation'
        },
        {
            name: 'Utilities',
            value: 'Utilities'
        },
        {
            name: 'Vendor',
            value: 'Vendor'
        },
        {
            name: 'Other',
            value: 'Other'
        }
    ];

}

export const removeItemInArray: Function = (array: Array<any>, itemToRemove: any): Array<any> => {
    const index: number = array.indexOf(itemToRemove);
    if (index > -1) array.splice(index, 1);
    return array;
}

export const removeItemInArrayObject: Function = (array: Array<any>, objectKey: string, objectValue: string): Array<any> => {
    const index = array.findIndex((key) => key[objectKey] === objectValue);
    array.splice(index, 1);
    return array;
}

export const paginateArray: Function = (items: Array<any>, pageSize: number, pageNumber: number): Array<any> => {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return items.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
};

export const getBodyAttachment: Function = (attachments: Array<{ filename: string, type: string, url: string, file?: File }>): FormData | undefined => {
    const form = new FormData();
    attachments
        .map((attachment) => attachment.file)
        .filter((file): file is File => !!file)
        .forEach((file) => {
            form.append(file.type.startsWith('image/') ? 'image' : file.type.startsWith('video/') ? 'video' : 'file', file, file.name || '');
        });
    let counter = 0;
    form.forEach((item) => { counter++; });
    return counter ? form : undefined;
};

export const arrayIndexChanged: Function = (arr1: any[], arr2: any[], key: string): boolean => {
    const idToIndex = new Map();
    arr1.forEach((obj, index) => { idToIndex.set(obj[key], index); });
    let changed = false;
    for (let i = 0; i < arr2.length; i++) {
        const obj = arr2[i];
        const expectedIndex = idToIndex.get(obj[key]);
        if (expectedIndex !== i) {
            changed = true;
        }
    }
    return changed;
}

export const copyClipboard: Function = (content: any): void => {
    navigator.clipboard.writeText(content);
}

export const searchURLParams: Function = (url: string, toSearch: string): string => {
    let tempUrl = new URL(url);
    let result = tempUrl.searchParams.get(toSearch);
    return result as string;
};

export const disableDOM: Function = (): void => {
    const body: any = document.getElementsByTagName("body");
    body[0].style = "pointer-events: none;"
}

export const enableDOM: Function = (): void => {
    const body: any = document.getElementsByTagName("body");
    body[0].style = "pointer-events: visible;"
}

export const convertBase64ToBlob: Function = (base64Image: string): Blob => {
    const parts = base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
}

export const addPrefixURL: Function = (url: string): string => {
    const prefixes = ['http://', 'https://', 'www://'];
    if (!prefixes.some(prefix => url.startsWith(prefix))) {
        return 'https://' + url;
    }
    return url;
}


export const getUniqueObjectsById: Function = (items: any[], key: string): any[] => {
    const uniqueIds: Set<any> = new Set();
    return items.filter(item => {
        if (!uniqueIds.has(item[key])) {
            uniqueIds.add(item[key]);
            return true;
        }
        return false;
    });
}
import { ChannelType } from '@/app/core/Models/ChannelTypes';

import {
  Contact,
  AssociatedContact,
  ContactsLookupDto,
  ContactsQueryParams,
  LookupContact,
  ContactAssociationDto,
  DeleteContactAssociationDto,
  AssociatedContacts,
  ContactTasks,
  AssociatedCompanies,
  ContactCompanyAssociationDto,
  IContactAttachments
} from '@/app/core/Models/contact';
import { Deal } from '@/app/core/Models/deal';

import { createAction, props, union } from '@ngrx/store';

export const GET_CONTACTS_LIST = '[Contacts] Get Contacts List';
export const GetContactsListAction = createAction(
  GET_CONTACTS_LIST,
  props<{ payload: ContactsQueryParams }>()
);

export const GET_CONTACTS_LIST_SUCCESS = '[Contacts] Get Contacts List Success';
export const GetContactsListSuccessAction = createAction(
  GET_CONTACTS_LIST_SUCCESS,
  props<{ payload: { params: ContactsQueryParams, contacts: Contact[] } }>()
);

export const GET_CONTACTS_LIST_FAIL = '[Contacts] Get Contacts List Fail';
export const GetContactsListFailAction = createAction(
  GET_CONTACTS_LIST_FAIL);

export const LOOKUP_CONTACTS = '[Contacts] lookup contacts action';
export const LookupContactsAction = createAction(
  LOOKUP_CONTACTS,
  props<{ payload: ContactsLookupDto }>()
);

export const LOOKUP_CONTACTS_SUCCESS = '[Contacts] lookup contacts success action';
export const LookupContactsSuccessAction = createAction(
  LOOKUP_CONTACTS_SUCCESS,
  props<{ payload: LookupContact[] }>()
);

export const SHOW_CONTACT_PAGE = '[Contacts] Show Contact Page';
export const ShowContactPageAction = createAction(
  SHOW_CONTACT_PAGE,
  props<{ payload: Contact }>()
);

export const SET_ACTIVE_CONTACT_ID = '[Contacts] set active contact id';
export const SetActiveContactId = createAction(
  SET_ACTIVE_CONTACT_ID,
  props<{ payload: { contactId: number, unclassified?: boolean } }>()
);

export const CSV_CONTACTS_IMPORTED = '[Contacts] csv contacts imported';
export const CsvContactsImportedAction = createAction(
  CSV_CONTACTS_IMPORTED,
  props<{ payload: { total_contacts: number, imported_contacts: number } }>()
);

/* ----------------------------- list associated deals to contact ----------------------------- */
export const GET_ASSOCIATE_DEALS_TO_CONTACT = '[CONTACT] Get Associated Deals To Contact';
export const GetAssociateDealsToContactAction = createAction(
  GET_ASSOCIATE_DEALS_TO_CONTACT,
  props<{ payload: ContactsQueryParams }>()
);

export const GET_ASSOCIATE_DEALS_TO_CONTACT_SUCCESS = '[CONTACT] Get Associate Deals To Contact Success';
export const GetAssociateDealsToContactSuccessAction = createAction(
  GET_ASSOCIATE_DEALS_TO_CONTACT_SUCCESS,
  props<{ payload: Deal[] }>()
);

export const GET_ASSOCIATE_DEALS_TO_CONTACT_FAIL = '[CONTACT] Get Associate Deals To Contact Fail';
export const GetAssociateDealsToContactFailAction = createAction(
  GET_ASSOCIATE_DEALS_TO_CONTACT_FAIL
);

/* ----------------------------- contacts to contacts associations ----------------------------- */
export const GET_ASSOCIATE_CONTACT_TO_CONTACT = '[CONTACT] Get Associated Contact To Contact';
export const GetAssociateContactToContactAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_CONTACT,
  props<{ payload: ContactsQueryParams }>()
);

export const GET_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS = '[CONTACT] Get Associate Contact To Contact Success';
export const GetAssociateContactToContactSuccessAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS,
  props<{ payload: AssociatedContacts }>()
);

export const GET_ASSOCIATE_CONTACT_TO_CONTACT_FAIL = '[CONTACT] Get Associate Contact To Contact Fail';
export const GetAssociateContactToContactFailAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_CONTACT_FAIL
);

export const CREATE_ASSOCIATE_CONTACT_TO_CONTACT = '[CONTACT] Create Associated Contact To Contact';
export const CreateAssociateContactToContactAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_CONTACT,
  props<{ payload: { data: ContactAssociationDto[], dialogId?: string } }>()
);

export const CREATE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS = '[CONTACT] Create Associate Contact To Contact Success';
export const CreateAssociateContactToContactSuccessAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS
);

export const CREATE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL = '[CONTACT] Create Associate Contact To Contact Fail';
export const CreateAssociateContactToContactFailAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL
);

export const UPDATE_ASSOCIATE_CONTACT_TO_CONTACT = '[CONTACT] Update Associated CONTACT To CONTACT';
export const UpdateAssociateContactToContactAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_CONTACT,
  props<{ payload: ContactAssociationDto }>()
);

export const UPDATE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS = '[CONTACT] Update Associate Contact To Contact Success';
export const UpdateAssociateContactToContactSuccessAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS
);

export const UPDATE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL = '[CONTACT] Update Associate Contact To Contact Fail';
export const UpdateAssociateContactToContactFailAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL
);

export const DELETE_ASSOCIATE_CONTACT_TO_CONTACT = '[CONTACT] Delete Associated Contact To Contact';
export const DeleteAssociateContactToContactAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_CONTACT,
  props<{ payload: DeleteContactAssociationDto }>()
);

export const DELETE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS = '[CONTACT] Delete Associate Contact To Contact Success';
export const DeleteAssociateContactToContactSuccessAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_CONTACT_SUCCESS
);

export const DELETE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL = '[CONTACT] Delete Associate Contact To Contact Fail';
export const DeleteAssociateContactToContactFailAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_CONTACT_FAIL
);

/* ----------------------------- contact tasks ----------------------------- */
export const GET_TASK_TO_CONTACT = '[CONTACT] List Contact Task'
export const GetContactTaskAction = createAction(
  GET_TASK_TO_CONTACT,
  props<{ payload: ContactsQueryParams }>()
)

export const GET_TASK_TO_CONTACT_SUCCESS = '[CONTACT] List Contact Task Success'
export const GetContactTaskSuccessAction = createAction(
  GET_TASK_TO_CONTACT_SUCCESS,
  props<{ payload: ContactTasks[] }>()
)

export const GET_TASK_TO_CONTACT_FAIL = '[CONTACT] List Contact Task Fail'
export const GetContactTaskFailAction = createAction(
  GET_TASK_TO_CONTACT_FAIL
)

/* ----------------------------- contacts to company associations ----------------------------- */
export const GET_ASSOCIATE_CONTACT_TO_COMPANY = '[CONTACT] Get Associated Contact To Company';
export const GetAssociateContactToCompanyAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: ContactsQueryParams }>()
);

export const GET_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[CONTACT] Get Associate Contact To Company Success';
export const GetAssociateContactToCompanySuccessAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS,
  props<{ payload: AssociatedCompanies }>()
);

export const GET_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[CONTACT] Get Associate Contact To Company Fail';
export const GetAssociateContactToCompanyFailAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

export const CREATE_ASSOCIATE_CONTACT_TO_COMPANY = '[CONTACT] Create Associated Contact To Company';
export const CreateAssociateContactToCompanyAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: { data: ContactCompanyAssociationDto[], dialogId?: string } }>()
);

export const CREATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[CONTACT] Create Associate Contact To Company Success';
export const CreateAssociateContactToCompanySuccessAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const CREATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[CONTACT] Create Associate Contact To Company Fail';
export const CreateAssociateContactToCompanyFailAction = createAction(
  CREATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY = '[CONTACT] Update Associated CONTACT To COmpany';
export const UpdateAssociateContactToCompanyAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: ContactCompanyAssociationDto }>()
);

export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[CONTACT] Update Associate Contact To Company Success';
export const UpdateAssociateContactToCompanySuccessAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[CONTACT] Update Associate Contact To Company Fail';
export const UpdateAssociateContactToCompanyFailAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY = '[CONTACT] Delete Associated Contact To Company';
export const DeleteAssociateContactToCompanyAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: ContactCompanyAssociationDto }>()
);

export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[CONTACT] Delete Associate Contact To Company Success';
export const DeleteAssociateContactToCompanySuccessAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[CONTACT] Delete Associate Contact To Company Fail';
export const DeleteAssociateContactToCompanyFailAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

export const CONTACT_MERGE_SUCCESS = '[Contacts] Contact Merge Success';
export const ContactMergeSuccessAction = createAction(
  CONTACT_MERGE_SUCCESS,
  props<{ payload: { id: string, mergedId: string } }>()
);


/* ----------------------------- attachments ----------------------------- */
export const GET_CONTACT_ATTACHMENTS = '[COMPANY] Get Contact Attachments';
export const GetContactAttachments = createAction(
  GET_CONTACT_ATTACHMENTS,
  props<{ payload: { contact_id: string } }>()
);

export const GET_CONTACT_ATTACHMENTS_SUCCESS = '[COMPANY] Get Contact Attachments Success';
export const GetContactAttachmentsSuccess = createAction(
  GET_CONTACT_ATTACHMENTS_SUCCESS,
  props<{ payload: IContactAttachments }>()
);

export const GET_CONTACT_ATTACHMENTS_FAIL = '[COMPANY] Get Contact Attachments Fail';
export const GetContactAttachmentsFail = createAction(
  GET_CONTACT_ATTACHMENTS_SUCCESS
);

const all = union({
  GetContactsListAction,
  LookupContactsAction,
  LookupContactsSuccessAction,
  ShowContactPageAction,
  SetActiveContactId,
  CsvContactsImportedAction,
  GetAssociateDealsToContactAction,
  GetAssociateDealsToContactSuccessAction,
  GetAssociateDealsToContactFailAction,
  GetAssociateContactToContactAction,
  GetAssociateContactToContactSuccessAction,
  GetAssociateContactToContactFailAction,
  CreateAssociateContactToContactAction,
  CreateAssociateContactToContactSuccessAction,
  CreateAssociateContactToContactFailAction,
  UpdateAssociateContactToContactAction,
  UpdateAssociateContactToContactSuccessAction,
  UpdateAssociateContactToContactFailAction,
  DeleteAssociateContactToContactAction,
  DeleteAssociateContactToContactSuccessAction,
  DeleteAssociateContactToContactFailAction,
  GetContactTaskAction,
  GetContactTaskSuccessAction,
  GetContactTaskFailAction,
  GetAssociateContactToCompanyAction,
  GetAssociateContactToCompanySuccessAction,
  GetAssociateContactToCompanyFailAction,
  CreateAssociateContactToCompanyAction,
  CreateAssociateContactToCompanySuccessAction,
  CreateAssociateContactToCompanyFailAction,
  UpdateAssociateContactToCompanyAction,
  UpdateAssociateContactToCompanySuccessAction,
  UpdateAssociateContactToCompanyFailAction,
  DeleteAssociateContactToCompanyAction,
  DeleteAssociateContactToCompanySuccessAction,
  DeleteAssociateContactToCompanyFailAction,
  ContactMergeSuccessAction,
  GetContactAttachments,
  GetContactAttachmentsSuccess,
  GetContactAttachmentsFail
});
export type ContactsActions = typeof all;
import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
})
export class InputWrapperComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() classes: string;
  value: string = '';
  @Input() type = 'text';
  @Input('customClasses') set updateDefaultClasses(classes: string) {
    this.defaultClasses = this.defaultClasses + ' ' + classes
  }
  @Input() ngStyle: { [key: string]: string }
  @Input() invalid?= false;
  @Input() iconLabel: string;
  @Input() maxLength: number;
  @Input() currentLength: number;

  defaultClasses = "flex flew-row items-center focus-within:border-primary border-2 px-0 rounded-md secondary-border-color surface-bg-color"
  isValid = true;

  onChange: Function = (value: any) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  constructor() { }

  ngOnInit(): void { }
}

import { CreateSynthConversationDto, UpdateSynthConversationDto, CreateSynthInteraction, UpdateSynthInteraction } from "@/app/Data/DTO/synthDto";
import { Observable, Observer } from "rxjs";
import { Synth, SynthConversation, SynthRecentConversation, SynthInteraction, RecentSynthConversationsQueryParams, SynthConversationInteractionsQuery, CreateSynthAgentDto, LinkSynthAgentDto } from "../Models/synth";

export abstract class ISynthRepository {

    abstract getSynths(): Observable<Synth[]>;

    abstract createSynthConversation(data: CreateSynthConversationDto): Observable<SynthConversation>;

    abstract updateSynthConversation(data: UpdateSynthConversationDto, synth_conversation_id: number | string): Observable<SynthConversation>;

    abstract getSynthConversation(): Observable<SynthConversation>;

    abstract getSynthRecentConversations(query: RecentSynthConversationsQueryParams): Observable<SynthRecentConversation[]>;

    abstract getSynthInteractionsByConversation(query: SynthConversationInteractionsQuery): Observable<SynthInteraction[]>;

    abstract createSynthInteraction(data: CreateSynthInteraction): Observable<SynthInteraction>;

    abstract updateSynthInteraction(data: UpdateSynthInteraction, synth_interaction_id: number | string): Observable<SynthInteraction>;

    abstract uploadKnowledgeBaseFiles(data: FormData, progress$?: Observer<any>): Observable<any>;

    abstract createSynthAgent(data: CreateSynthAgentDto): Observable<Synth>;

    abstract linkSynthAgent(data: LinkSynthAgentDto): Observable<any>

}
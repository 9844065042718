import {
  AssociatedCompanies,
  AssociatedCompany,
  CompaniesQueryParams,
  Company,
  CompanyCustomFields,
  CompanyNotes,
  CompanyTasks
} from '@/app/core/Models/company';
import {
  AddPeopleCompanyDto,
  CreateCompanyDto,
  UpdateCompanyRequest,
  UpdateAssociatedContactRelationshipTypeDto,
  CreateCompanyNoteDto,
  DeleteCompanyNoteDto,
  DeleteCompanyCustomFieldDto,
  AddCompanyCustomFieldsDto,
  AssociateCompanyToCompanyDto,
  DeleteCompanyAssociationDto
} from '@/app/Data/DTO/companyDto';

import { Deal } from '@/app/core/Models/deal';

import { createAction, props, union } from '@ngrx/store';

export const GET_COMPANIES_LIST = '[Companies] Get Companies List';
export const GetCompaniesListAction = createAction(
  GET_COMPANIES_LIST,
  props<{ payload: CompaniesQueryParams }>()
);

export const GET_COMPANY_LIST_SUCCESS = '[Companies] Get Companies List Success';
export const GetCompaniesListSuccessAction = createAction(
  GET_COMPANY_LIST_SUCCESS,
  props<{ payload: { params: CompaniesQueryParams, companies: Company[] } }>()
);

export const GET_COMPANY_LIST_FAIL = '[Companies] Get Companies List Fail';
export const GetCompaniesListFailAction = createAction(
  GET_COMPANY_LIST_FAIL);

export const SHOW_COMPANY_PAGE = '[Companies] Show Company Page';
export const ShowCompanyPageAction = createAction(
  SHOW_COMPANY_PAGE,
  props<{ payload: Company }>()
);

export const SET_ACTIVE_COMPANY_ID = '[Companies] set active company id';
export const SetActiveCompanyId = createAction(
  SET_ACTIVE_COMPANY_ID,
  props<{ payload: { companyId: string } }>()
);

export const RESET_COMPANY_STATE = '[COMPANY] Reset company State';
export const ResetCompanyStateAction = createAction(
  RESET_COMPANY_STATE
);

/* ----------------------------- create company ----------------------------- */
export const COMPANY_CREATE = '[COMPANY] Company create';
export const CompanyCreateAction = createAction(
  COMPANY_CREATE,
  props<{ payload: { data: CreateCompanyDto, dialogId?: string } }>()
);

export const COMPANY_CREATE_SUCCESS = '[COMPANY] Company create success';
export const CompanyCreateSuccessAction = createAction(
  COMPANY_CREATE_SUCCESS
);

export const COMPANY_CREATE_FAIL = '[COMPANY] Company create Fail';
export const CompanyCreateFailAction = createAction(
  COMPANY_CREATE_FAIL
);

/* ----------------------------- create company ----------------------------- */
export const COMPANY_UPDATE = '[COMPANY] Company update';
export const CompanyUpdateAction = createAction(
  COMPANY_UPDATE,
  props<{ payload: { data: UpdateCompanyRequest, dialogId?: string } }>()
);

export const COMPANY_UPDATE_SUCCESS = '[COMPANY] Company update success';
export const CompanyUpdateSuccessAction = createAction(
  COMPANY_UPDATE_SUCCESS
);

export const COMPANY_UPDATE_FAIL = '[COMPANY] Company update Fail';
export const CompanyUpdateFailAction = createAction(
  COMPANY_UPDATE_FAIL
);

/* ------------------------------ fetch company ----------------------------- */
export const FETCH_COMPANY_BY_ID = '[COMPANY] Fetch Company By Id';
export const FetchCompanyByIdAction = createAction(
  FETCH_COMPANY_BY_ID,
  props<{ payload: string }>()
);

export const FETCH_COMPANY_BY_ID_SUCCESS = '[COMPANY] Fetch Company By Id Success';
export const FetchCompanyByIdSuccessAction = createAction(
  FETCH_COMPANY_BY_ID_SUCCESS,
  props<{ payload: Company }>()
);

export const FETCH_COMPANY_BY_ID_FAILED = '[COMPANY] Fetch Company By Id Failed';
export const FetchCompanyByIdFailedAction = createAction(
  FETCH_COMPANY_BY_ID_FAILED,
  props<{ payload: { status: number } }>()
);

/* ----------------------------- delete company ----------------------------- */
export const DELETE_COMPANY = '[COMPANYS] Delete Company';
export const DeleteCompanyAction = createAction(
  DELETE_COMPANY,
  props<{ payload: string[] }>()
);
export const DELETE_COMPANY_SUCCESS = '[COMPANYS] Company Successfully Deleted';
export const CompanyDeleteSuccessAction = createAction(
  DELETE_COMPANY_SUCCESS,
  props<{ payload: string[] }>()
);
export const DELETE_COMPANY_FAIL = '[COMPANYS] Failed To Delete Company';
export const CompanyDeleteFailAction = createAction(
  DELETE_COMPANY_FAIL,
  props<{ payload: string }>()
);

/* ----------------------------- associate contact company ----------------------------- */
export const ASSOCIATE_CONTACT_TO_COMPANY = '[COMPANY] Associate Contact To Company';
export const AssociateContactToCompanyAction = createAction(
  ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: { data: AddPeopleCompanyDto[], dialogId?: string } }>()
);

export const ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[COMPANY] Associate Contact To Company Success';
export const AssociateContactCompanySuccessAction = createAction(
  ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[COMPANY] Associate Contact To Company Fail';
export const AssociateContactCompanyFailAction = createAction(
  ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

/* ----------------------------- list associated contacts to company ----------------------------- */
export const GET_ASSOCIATE_CONTACT_TO_COMPANY = '[COMPANY] List Associate Contact To Company';
export const GetAssociateContactToCompanyAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
);

export const GET_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[COMPANY] List Associate Contact To Company Success';
export const GetAssociateContactCompanySuccessAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS,
  props<{ payload: AddPeopleCompanyDto[] }>()
);

export const GET_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[COMPANY] List Associate Contact To Company Fail';
export const GetAssociateContactCompanyFailAction = createAction(
  GET_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

/* ----------------------------- delete associated contacts to company ----------------------------- */
export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY = '[COMPANY] Delete Associate Contact To Company';
export const DeleteAssociateContactToCompanyAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: { contact_id: string, yobi_crm_company_id: number | string } }>()
);

export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[COMPANY] Delete Associate Contact To Company Success';
export const DeleteAssociateContactCompanySuccessAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const DELETE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[COMPANY] Delete Associate Contact To Company Fail';
export const DeleteAssociateContactCompanyFailAction = createAction(
  DELETE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

/* ----------------------------- update associated contacts to company ----------------------------- */
export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY = '[COMPANY] Update Associate Contact To Company';
export const UpdateAssociateContactToCompanyAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY,
  props<{ payload: UpdateAssociatedContactRelationshipTypeDto }>()
);

export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS = '[COMPANY] Update Associate Contact To Company Success';
export const UpdateAssociateContactCompanySuccessAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_SUCCESS
);

export const UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL = '[COMPANY] Update Associate Contact To Company Fail';
export const UpdateAssociateContactCompanyFailAction = createAction(
  UPDATE_ASSOCIATE_CONTACT_TO_COMPANY_FAIL
);

/* ----------------------------- company notes ----------------------------- */
export const CREATE_NOTE_TO_COMPANY = '[COMPANY] Create Company Note'
export const CreateCompanyNoteAction = createAction(
  CREATE_NOTE_TO_COMPANY,
  props<{ payload: CreateCompanyNoteDto }>()
)

export const CREATE_NOTE_TO_COMPANY_SUCCESS = '[COMPANY] Create Company Note Success'
export const CreateCompanyNoteSuccessAction = createAction(
  CREATE_NOTE_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyNotes }>()
)

export const CREATE_NOTE_TO_COMPANY_FAIL = '[COMPANY] Create Company Note Fail'
export const CreateCompanyNoteFailAction = createAction(
  CREATE_NOTE_TO_COMPANY_FAIL
)

export const UPDATE_NOTE_TO_COMPANY = '[COMPANY] Update Company Note'
export const UpdateCompanyNoteAction = createAction(
  UPDATE_NOTE_TO_COMPANY,
  props<{ payload: CreateCompanyNoteDto }>()
)

export const UPDATE_NOTE_TO_COMPANY_SUCCESS = '[COMPANY] Update Company Note Success'
export const UpdateCompanyNoteSuccessAction = createAction(
  UPDATE_NOTE_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyNotes }>()
)

export const UPDATE_NOTE_TO_COMPANY_FAIL = '[COMPANY] Update Company Note Fail'
export const UpdateCompanyNoteFailAction = createAction(
  UPDATE_NOTE_TO_COMPANY_FAIL
)

export const DELETE_NOTE_TO_COMPANY = '[COMPANY] Delete Company Note'
export const DeleteCompanyNoteAction = createAction(
  DELETE_NOTE_TO_COMPANY,
  props<{ payload: DeleteCompanyNoteDto }>()
)

export const DELETE_NOTE_TO_COMPANY_SUCCESS = '[COMPANY] Delete Company Note Success'
export const DeleteCompanyNoteSuccessAction = createAction(
  DELETE_NOTE_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyNotes }>()
)

export const DELETE_NOTE_TO_COMPANY_FAIL = '[COMPANY] Delete Company Note Fail'
export const DeleteCompanyNoteFailAction = createAction(
  DELETE_NOTE_TO_COMPANY_FAIL
)

export const GET_NOTE_TO_COMPANY = '[COMPANY] List Company Note'
export const GetCompanyNoteAction = createAction(
  GET_NOTE_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
)

export const GET_NOTE_TO_COMPANY_SUCCESS = '[COMPANY] List Company Note Success'
export const GetCompanyNoteSuccessAction = createAction(
  GET_NOTE_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyNotes[] }>()
)

export const GET_NOTE_TO_COMPANY_FAIL = '[COMPANY] List Company Note Fail'
export const GetCompanyNoteFailAction = createAction(
  GET_NOTE_TO_COMPANY_FAIL
)

/* ----------------------------- company tasks ----------------------------- */
export const GET_TASK_TO_COMPANY = '[COMPANY] List Company Task'
export const GetCompanyTaskAction = createAction(
  GET_TASK_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
)

export const GET_TASK_TO_COMPANY_SUCCESS = '[COMPANY] List Company Task Success'
export const GetCompanyTaskSuccessAction = createAction(
  GET_TASK_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyTasks[] }>()
)

export const GET_TASK_TO_COMPANY_FAIL = '[COMPANY] List Company Task Fail'
export const GetCompanyTaskFailAction = createAction(
  GET_TASK_TO_COMPANY_FAIL
)

/* ----------------------------- company custom fields ----------------------------- */
export const GET_CUSTOM_FIELDS_TO_COMPANY = '[COMPANY] List Company Custom Fields'
export const GetCompanyCustomFieldsAction = createAction(
  GET_CUSTOM_FIELDS_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
)

export const GET_CUSTOM_FIELDS_TO_COMPANY_SUCCESS = '[COMPANY] List Company Custom Fields Success'
export const GetCompanyCustomFieldsSuccessAction = createAction(
  GET_CUSTOM_FIELDS_TO_COMPANY_SUCCESS,
  props<{ payload: CompanyCustomFields[] }>()
)

export const GET_CUSTOM_FIELDS_TO_COMPANY_FAIL = '[COMPANY] List Company Custom Fields Fail'
export const GetCompanyCustomFieldsFailAction = createAction(
  GET_CUSTOM_FIELDS_TO_COMPANY_FAIL
)

export const DELETE_CUSTOM_FIELDS_TO_COMPANY = '[COMPANY] Delete Company Custom Fields'
export const DeleteCompanyCustomFieldsAction = createAction(
  DELETE_CUSTOM_FIELDS_TO_COMPANY,
  props<{ payload: DeleteCompanyCustomFieldDto }>()
)

export const DELETE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS = '[COMPANY] Delete Company Custom Fields Success'
export const DeleteCompanyCustomFieldsSuccessAction = createAction(
  DELETE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS
)

export const DELETE_CUSTOM_FIELDS_TO_COMPANY_FAIL = '[COMPANY] Delete Company Custom Fields Fail'
export const DeleteCompanyCustomFieldsFailAction = createAction(
  DELETE_CUSTOM_FIELDS_TO_COMPANY_FAIL
)

export const CREATE_CUSTOM_FIELDS_TO_COMPANY = '[COMPANY] Create Company Custom Fields'
export const CreateCompanyCustomFieldsAction = createAction(
  CREATE_CUSTOM_FIELDS_TO_COMPANY,
  props<{ payload: { data: AddCompanyCustomFieldsDto[], dialogId: string } }>()
)

export const CREATE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS = '[COMPANY] Create Company Custom Fields Success'
export const CreateCompanyCustomFieldsSuccessAction = createAction(
  CREATE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS
)

export const CREATE_CUSTOM_FIELDS_TO_COMPANY_FAIL = '[COMPANY] Create Company Custom Fields Fail'
export const CreateCompanyCustomFieldsFailAction = createAction(
  CREATE_CUSTOM_FIELDS_TO_COMPANY_FAIL
)

export const UPDATE_CUSTOM_FIELDS_TO_COMPANY = '[COMPANY] Update Company Custom Fields'
export const UpdateCompanyCustomFieldsAction = createAction(
  UPDATE_CUSTOM_FIELDS_TO_COMPANY,
  props<{ payload: { data: AddCompanyCustomFieldsDto[], dialogId: string } }>()
)

export const UPDATE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS = '[COMPANY] Update Company Custom Fields Success'
export const UpdateCompanyCustomFieldsSuccessAction = createAction(
  UPDATE_CUSTOM_FIELDS_TO_COMPANY_SUCCESS
)

export const UPDATE_CUSTOM_FIELDS_TO_COMPANY_FAIL = '[COMPANY] Update Company Custom Fields Fail'
export const UpdateCompanyCustomFieldsFailAction = createAction(
  UPDATE_CUSTOM_FIELDS_TO_COMPANY_FAIL
)

/* ----------------------------- company to company associations ----------------------------- */
export const GET_ASSOCIATE_COMPANY_TO_COMPANY = '[COMPANY] Get Associated Company To Company';
export const GetAssociateCompanyToCompanyAction = createAction(
  GET_ASSOCIATE_COMPANY_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
);

export const GET_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS = '[COMPANY] Get Associate Company To Company Success';
export const GetAssociateCompanyToCompanySuccessAction = createAction(
  GET_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS,
  props<{ payload: AssociatedCompanies }>()
);

export const GET_ASSOCIATE_COMPANY_TO_COMPANY_FAIL = '[COMPANY] Get Associate Company To Company Fail';
export const GetAssociateCompanyToCompanyFailAction = createAction(
  GET_ASSOCIATE_COMPANY_TO_COMPANY_FAIL
);

export const CREATE_ASSOCIATE_COMPANY_TO_COMPANY = '[COMPANY] Create Associated Company To Company';
export const CreateAssociateCompanyToCompanyAction = createAction(
  CREATE_ASSOCIATE_COMPANY_TO_COMPANY,
  props<{ payload: { data: AssociateCompanyToCompanyDto[], dialogId?: string } }>()
);

export const CREATE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS = '[COMPANY] Create Associate Company To Company Success';
export const CreateAssociateCompanyToCompanySuccessAction = createAction(
  CREATE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS
);

export const CREATE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL = '[COMPANY] Create Associate Company To Company Fail';
export const CreateAssociateCompanyToCompanyFailAction = createAction(
  CREATE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL
);

export const UPDATE_ASSOCIATE_COMPANY_TO_COMPANY = '[COMPANY] Update Associated Company To Company';
export const UpdateAssociateCompanyToCompanyAction = createAction(
  UPDATE_ASSOCIATE_COMPANY_TO_COMPANY,
  props<{ payload: AssociateCompanyToCompanyDto }>()
);

export const UPDATE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS = '[COMPANY] Update Associate Company To Company Success';
export const UpdateAssociateCompanyToCompanySuccessAction = createAction(
  UPDATE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS
);

export const UPDATE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL = '[COMPANY] Update Associate Company To Company Fail';
export const UpdateAssociateCompanyToCompanyFailAction = createAction(
  UPDATE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL
);

export const DELETE_ASSOCIATE_COMPANY_TO_COMPANY = '[COMPANY] Delete Associated Company To Company';
export const DeleteAssociateCompanyToCompanyAction = createAction(
  DELETE_ASSOCIATE_COMPANY_TO_COMPANY,
  props<{ payload: DeleteCompanyAssociationDto }>()
);

export const DELETE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS = '[COMPANY] Delete Associate Company To Company Success';
export const DeleteAssociateCompanyToCompanySuccessAction = createAction(
  DELETE_ASSOCIATE_COMPANY_TO_COMPANY_SUCCESS
);

export const DELETE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL = '[COMPANY] Delete Associate Company To Company Fail';
export const DeleteAssociateCompanyToCompanyFailAction = createAction(
  DELETE_ASSOCIATE_COMPANY_TO_COMPANY_FAIL
);

/* ----------------------------- list associated deals to company ----------------------------- */
export const GET_ASSOCIATE_DEALS_TO_COMPANY = '[COMPANY] Get Associated Deals To Company';
export const GetAssociateDealsToCompanyAction = createAction(
  GET_ASSOCIATE_DEALS_TO_COMPANY,
  props<{ payload: CompaniesQueryParams }>()
);

export const GET_ASSOCIATE_DEALS_TO_COMPANY_SUCCESS = '[COMPANY] Get Associate Deals To Company Success';
export const GetAssociateDealsToCompanySuccessAction = createAction(
  GET_ASSOCIATE_DEALS_TO_COMPANY_SUCCESS,
  props<{ payload: Deal[] }>()
);

export const GET_ASSOCIATE_DEALS_TO_COMPANY_FAIL = '[COMPANY] Get Associate Deals To Company Fail';
export const GetAssociateDealsToCompanyFailAction = createAction(
  GET_ASSOCIATE_DEALS_TO_COMPANY_FAIL
);

const all = union({
  CompanyCreateAction,
  CompanyUpdateAction,
  GetCompaniesListAction,
  GetCompaniesListSuccessAction,
  GetCompaniesListFailAction,
  ShowCompanyPageAction,
  SetActiveCompanyId,
  ResetCompanyStateAction,
  CompanyCreateSuccessAction,
  FetchCompanyByIdAction,
  FetchCompanyByIdSuccessAction,
  FetchCompanyByIdFailedAction,
  DeleteCompanyAction,
  CompanyDeleteSuccessAction,
  CompanyDeleteFailAction,
  AssociateContactToCompanyAction,
  AssociateContactCompanySuccessAction,
  AssociateContactCompanyFailAction,
  GetAssociateContactToCompanyAction,
  GetAssociateContactCompanySuccessAction,
  GetAssociateContactCompanyFailAction,
  DeleteAssociateContactToCompanyAction,
  DeleteAssociateContactCompanySuccessAction,
  DeleteAssociateContactCompanyFailAction,
  UpdateAssociateContactToCompanyAction,
  UpdateAssociateContactCompanySuccessAction,
  UpdateAssociateContactCompanyFailAction,
  CreateCompanyNoteAction,
  CreateCompanyNoteSuccessAction,
  CreateCompanyNoteFailAction,
  UpdateCompanyNoteAction,
  UpdateCompanyNoteSuccessAction,
  UpdateCompanyNoteFailAction,
  DeleteCompanyNoteAction,
  DeleteCompanyNoteSuccessAction,
  DeleteCompanyNoteFailAction,
  GetCompanyNoteAction,
  GetCompanyNoteSuccessAction,
  GetCompanyNoteFailAction,
  GetCompanyTaskAction,
  GetCompanyTaskSuccessAction,
  GetCompanyTaskFailAction,
  GetCompanyCustomFieldsAction,
  GetCompanyCustomFieldsSuccessAction,
  GetCompanyCustomFieldsFailAction,
  DeleteCompanyCustomFieldsAction,
  DeleteCompanyCustomFieldsSuccessAction,
  DeleteCompanyCustomFieldsFailAction,
  CreateCompanyCustomFieldsAction,
  CreateCompanyCustomFieldsSuccessAction,
  CreateCompanyCustomFieldsFailAction,
  UpdateCompanyCustomFieldsAction,
  UpdateCompanyCustomFieldsSuccessAction,
  UpdateCompanyCustomFieldsFailAction,
  GetAssociateCompanyToCompanyAction,
  GetAssociateCompanyToCompanySuccessAction,
  GetAssociateCompanyToCompanyFailAction,
  CreateAssociateCompanyToCompanyAction,
  CreateAssociateCompanyToCompanySuccessAction,
  CreateAssociateCompanyToCompanyFailAction,
  UpdateAssociateCompanyToCompanyAction,
  UpdateAssociateCompanyToCompanySuccessAction,
  UpdateAssociateCompanyToCompanyFailAction,
  DeleteAssociateCompanyToCompanyAction,
  DeleteAssociateCompanyToCompanySuccessAction,
  DeleteAssociateCompanyToCompanyFailAction,
  GetAssociateDealsToCompanyAction,
  GetAssociateDealsToCompanySuccessAction,
  GetAssociateDealsToCompanyFailAction
});

export type CompaniesActions = typeof all;
<div
    *ngFor="let attachment of attachments; let i = index"
    class="border-2 secondary-border-color relative rounded-md file-holder shrink-0 max-w-full max-h-fit"
    [ngStyle]="{height: 'fit-content'}">
    <div
        (click)="remove.emit(i)"
        *ngIf="canRemove"
        style="right: -0.5rem; top: -0.5rem"
        class="remover bg-gray-500 hover:bg-warning w-5 h-5 absolute rounded-full flex opacity-0 items-center justify-center cursor-pointer z-10">
        <mat-icon
            class="close-icon text-white">
            close
        </mat-icon>
    </div>
    <ng-container [ngSwitch]="attachment.type">
        <img
            *ngSwitchCase="'image'"
            [ngStyle]="{width: size}"
            class="h-full object-cover rounded cursor-pointer"
            [src]="attachment.url"
            ngxViewer
            [viewerOptions]="viewerOptions"
        />
        <img
            *ngSwitchCase="'pdf'"
            [ngStyle]="{height: size}"
            class="h-full object-cover rounded cursor-pointer"
            (click)="openFile(attachment.url)"
            src="assets/icons/pdf-icon.png"
        />
        <video
            controls
            *ngSwitchCase="'video'"
            class="h-full object-cover rounded"
            [src]="attachment.url"
        ></video>
        <audio
            #audio
            controls
            *ngSwitchCase="'audio'"
            class="h-12 object-cover rounded bg-gray-100 max-w-xs"
            [src]="attachment.url"
        ></audio>
        <div
            *ngSwitchDefault
            class="h-full object-cover rounded overflow-hidden p-3 flex items-center justify-center bg-gray-100 cursor-pointer"
            style="max-width: 8rem;"
            (click)="openFile(attachment.url)">
            <span class="text-base text-zinc-800 truncate">
            {{ attachment.name || 'file' + '('+i+')'}}
            </span>
        </div>
    </ng-container>
  <div *ngIf="attachment.type !=='file'">
     {{ attachment.name ? attachment.name : attachment.filename | filenameFormatter}}
  </div>
</div>

import { IMessagesRepository } from '@/app/core/IRepositories/IMessagesRepository';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { Inbox } from '@/app/core/Models/inbox';
import { RecentInteraction, RecentMessagesQueryParams } from '@/app/core/Models/Interaction';
import { RecentInteractionsFilter } from '@/app/core/Models/RecentInteractionsFilter';
import { AppState } from '@/app/State/AppState';
import { SelectEmailInboxAction } from '@/app/State/email-inbox/email-inbox.action';
import {
  FetchRecentMessagesAction,
  InboxSelectionAction,
  OpenConversationThreadAction,
  UpdateRecentInteractionsFilterAction
} from '@/app/State/Inbox/inbox.action';
import {
  selectFetchingInboxes,
  selectInboxes,
  selectIsRecentInteractionFetching,
  selectRecentInteractionsFilter,
  selectRecentInteractionsPage,
  selectRecentInteractionsState,
  selectSelectedInbox,
} from '@/app/State/Inbox/inbox.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest, debounceTime,
  map, shareReplay,
  switchMap
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesViewModel {

  selectRecentInteractionsState$ = this.store.select(
    selectRecentInteractionsState
  );
  selectRecentInteractionsStatePage$ = this.store.select(
    selectRecentInteractionsPage
  );

  isFetching$ = this.store
    .select(selectIsRecentInteractionFetching)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  recentInteractionsFilter$ = this.store
    .select(selectRecentInteractionsFilter)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  inboxes$ = this.store
    .select(selectInboxes)
    .pipe(
      map(inboxes => [...inboxes].sort((a, b) => !a.is_visible ? 1 : !b.is_visible ? -1 : 0)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  fetchingInboxes$ = this.store
    .select(selectFetchingInboxes)
  selectedInboxId$ = new BehaviorSubject<any | undefined>(undefined);
  selectedInbox$ = this.store
    .select(selectSelectedInbox)
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  recentMessages$ = combineLatest([
    this.selectRecentInteractionsStatePage$,
    this.recentInteractionsFilter$,
    this.selectedInbox$
  ]).pipe(
    debounceTime(150),
    switchMap(([page, filter, inbox]) =>
      this.messageRepository.getLocalRecentMessages({
        page,
        page_size: 30,
        filter,
        inboxId: inbox?.is_channel ? inbox.inbox_id : undefined
      })
    )
  );


  constructor(
    private store: Store<AppState>,
    private messageRepository: IMessagesRepository,
  ) { }


  deleteRecentInteractionByUnclassifiedChannelId(unclassifiedId: number) {
    this.messageRepository.deleteLocalRecentInteractionByUnclassifiedChannelId(unclassifiedId)
  }

  fetchRecentMessages(query: RecentMessagesQueryParams): void {
    this.store.dispatch(FetchRecentMessagesAction({ payload: query }));
  }

  openConversationThread(payload: { threadId: number; unClassified: boolean; interaction?: RecentInteraction }): void {
    this.store.dispatch(OpenConversationThreadAction({ payload }));
  }

  updateRecentInteractionFilter(filter: RecentInteractionsFilter): void {
    //this.resetCurrentRecentInteractionsPage();
    this.store.dispatch(UpdateRecentInteractionsFilterAction({ payload: filter }));
  }

  selectInbox(inbox?: Inbox) {
    this.selectedInboxId$.next(inbox?.inbox_type === 'tags' ? inbox.inbox_id : undefined);
    inbox && [ChannelType.Outlook, ChannelType.Google].includes(inbox?.channel_type as any)
      ? this.store.dispatch(SelectEmailInboxAction({ payload: { inbox } }))
      : this.store.dispatch(InboxSelectionAction({ payload: { inbox } }));
  }
}

import { Observable } from 'rxjs';
import { ContactChannel } from '../Models/Channel';
import { Contact, ContactsLookupDto, ContactsQueryParams, LookupContact } from '../Models/contact';

export abstract class IContactsRepository {
  abstract getContacts(params: ContactsQueryParams): Observable<Contact[]>;
  abstract lookupContacts(request: ContactsLookupDto): Observable<LookupContact[]>;
  abstract saveContacts(contacts: Contact[], params: ContactsQueryParams): void;
  abstract getLocalContacts(): Observable<Contact[]>;
  abstract mergeContact(id: String, mergedId: string): Observable<Contact>;

}

import { IContactRepository } from '@/app/core/IRepositories/IContactRepository';
import { from, last, map, Observable, Observer, pluck, tap } from 'rxjs';
import { Channel, ContactChannel, UnclassifiedChannel } from '@/app/core/Models/Channel';
import { environment } from '@/environments/environment';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { AssociatedCompanies, AssociatedCompany, AssociatedContacts, Contact, ContactAssociationDto, ContactCompanyAssociationDto, ContactsQueryParams, ContactTasks, DeleteContactAssociationDto } from '@/app/core/Models/contact';
import { HttpService } from '@/app/Data/services/Networking/HttpService';
import { DatabaseService } from '@/app/Data/services/Database/database.service';
import { Injectable } from '@angular/core';
import { liveQuery, Table } from 'dexie';
import { SuccessResponse } from '@/app/Data/DTO/successResponse';
import { ContactBlockDto } from '@/app/Data/DTO/ContactBlockDto';
import { ContactImageUpdatResponseDto } from '../DTO/contactImageUpdatResponseDto';
import { getUploadEventProgress } from '@/app/Utilities/functions/getUploadEventProgress';
import { Deal } from '@/app/core/Models/deal';

@Injectable()
export class ContactRepository implements IContactRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService
  ) { }

  getContactById(contactId: number): Observable<Contact> {
    const requestURL = `${environment.apiURL}contacts/${contactId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Contact>;
        return res.results;
      })
    );
  }

  getUnclassifiedChannelById(channelId: number): Observable<[UnclassifiedChannel]> {
    const requestURL = `${environment.apiURL}unclassified-channels/channels/${channelId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<[UnclassifiedChannel]>;
        return res.results;
      })
    );
  }

  getContactChannels(contactId: number): Observable<ContactChannel[]> {
    const requestURL = `${environment.apiURL}contacts/${contactId}/channels`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactChannel[]>;
        return res.results;
      })
    );
  }

  saveContactChannels(contactId: number, channels: ContactChannel[]): void {
    this.databaseService
      .transaction('rw!', this.databaseService.contactsChannels, async () => {
        const contactLocalChannels = await this.databaseService.contactsChannels
          .where('contact_id')
          .equals(contactId)
          .toArray();
        const newChannelsKeys = channels.map((channel) => channel.channel_id);
        const deletedChannels = contactLocalChannels
          .filter((item) => newChannelsKeys.indexOf(item.channel_id) == -1)
          .map((item) => item.channel_id as unknown as string);
        await this.databaseService.contactsChannels.bulkDelete(deletedChannels);
        await this.databaseService.contactsChannels.bulkPut(channels);
      })
      .catch((error) => {
        console.log('Transaction Failed');
      });
  }

  getLocalContactWithChannels(contactId: number): Observable<Contact | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          [
            this.databaseService.contacts,
            this.databaseService.contactsChannels,
          ],
          async () => {
            this.databaseService.contacts.mapToClass(Contact);
            const contact = await this.databaseService.contacts.get(contactId as unknown as string)
            if (contact) {
              const channels = await this.databaseService.contactsChannels
                .where({ contact_id: contactId })
                .toArray();
              contact.channels = channels;
            }
            return contact;
          }
        );
      })
    );
  }

  saveContact(contact: Contact) {
    contact = Object.assign(new Contact(), contact, {
      given_name: contact.given_name?.trim(),
      family_name: contact.family_name?.trim(),
      full_name: `${contact.given_name?.trim() ?? ''} ${contact.family_name?.trim() ?? ''}`.trim().toLowerCase()
    });
    this.databaseService.contacts.put(contact);
    this.databaseService.recentInteractions.where({ 'yobi_contact_id': contact.contact_id }).modify({
      avatar: contact.avatar
    }).catch(e => {
      console.error(e)
    })
  }

  getLocalContactById(contactId: number): Observable<Contact | undefined> {
    this.databaseService.contacts.mapToClass(Contact);
    return from(
      liveQuery(() => {
        return this.databaseService.contacts.get(contactId as unknown as string);
      })
    );
  }

  getLocalContactChannelsById(contactId: number): Observable<ContactChannel[]> {
    this.databaseService.contactsChannels.mapToClass(ContactChannel);
    return from(
      liveQuery(() => {
        return this.databaseService.contactsChannels
          .where('contact_id')
          .equals(contactId)
          .toArray();
      })
    );
  }

  createContact(data: Contact): Observable<Contact> {
    const requestURL = `${environment.apiURL}contacts`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Contact>;
        return res.results;
      })
    );
  }

  editContact(contactId: number, data: Contact): Observable<Contact> {
    const requestURL = `${environment.apiURL}contacts/${contactId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Contact>;
        return res.results;
      })
    );
  }

  deleteContact(contactId: number): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}contacts/${contactId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  deleteLocalContact(contactId: number): void {
    this.databaseService.contacts.delete(contactId as unknown as string).then().catch();
  }

  blockContact(request: ContactBlockDto): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}block_unblock_numbers`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<any>;
        return res.results;
      })
    );
  }

  updateProfileImage(
    data: FormData,
    contactId: number,
    progress$: Observer<any>
  ): Observable<ContactImageUpdatResponseDto> {
    const requestURL = `${environment.apiURL}contacts/update_avatar?contact_id=${contactId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false,
      null,
      true
    );
    return this.httpService.upload(options).pipe(
      tap((event: any) => getUploadEventProgress(event, progress$)),
      last(),
      pluck('body'),
      pluck('result'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactImageUpdatResponseDto>;
        return res.results;
      })
    );
  }

  deleteProfileImage(contactId: number): Observable<SuccessResponse> {
    const requestURL = `${environment.apiURL}contacts/delete_avatar?contact_id=${contactId}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      null,
      false,
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getAssociatedDealsToContact(params: ContactsQueryParams): Observable<Deal[]> {
    const requestURL = `${environment.apiURL}contact/${params.contact_id}/deals`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Deal[]>;
        return res.results;
      })
    );
  }

  getAssociatedDealsToContactLocal(contactId: string): Observable<Deal[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.contacts,
          async () => {
            const contact = await this.databaseService.contacts.get({ 'contact_id': contactId });
            return contact?.associated_deals;
          }
        )
      })
    );
  }

  updateLocalContactDetails(contactId: string, details: any): void {
    this.databaseService.contacts.update(contactId, details).then();
  }

  associateContactToCotact(data: ContactAssociationDto[]): Observable<ContactAssociationDto[]> {
    const requestURL = `${environment.apiURL}contacts-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactAssociationDto[]>;
        return res.results;
      })
    );
  }

  getAssociatedContactToContactLocal(contactId: string) {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.contacts,
          async () => {
            const contact = await this.databaseService.contacts.get({ 'associated_contacts.contact_id': contactId });
            return contact?.associated_contacts?.associated_contacts
          }
        )
      })
    );
  }

  getAssociatedContactToContact(params: ContactsQueryParams): Observable<AssociatedContacts> {
    const requestURL = `${environment.apiURL}contact/${params.contact_id}/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AssociatedContacts>;
        return res.results;
      })
    );
  }

  removeAssociatedContactToContact(data: DeleteContactAssociationDto) {
    const requestURL = `${environment.apiURL}contacts-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  updateAssociatedContactAssociationType(data: ContactAssociationDto) {
    const requestURL = `${environment.apiURL}contacts-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      data,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<SuccessResponse>;
        return res.results;
      })
    );
  }

  getContactTasks(params: ContactsQueryParams): Observable<ContactTasks[]> {
    const requestURL = `${environment.apiURL}contact/${params.contact_id}/tasks?page=${params.page}&page_size=${params.page_size || 25}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactTasks[]>;
        return res.results;
      })
    );
  }

  getAssociatedContactTasksLocal(contactId: string): Observable<ContactTasks[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.contacts,
          async () => {
            const contact = await this.databaseService.contacts.get({ 'contact_id': contactId });
            return contact?.associated_tasks;
          }
        );
      })
    );
  }

  getAssociatedContactToCompany(params: ContactsQueryParams): Observable<AssociatedCompanies> {
    const requestURL = `${environment.apiURL}contacts/${params.contact_id}/company-associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<AssociatedCompanies>;
        return res.results;
      })
    );
  }

  getAssociatedContactToCompanyLocal(contactId: string): Observable<AssociatedCompany[] | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.contacts,
          async () => {
            const contact = await this.databaseService.contacts.get({ 'contact_id': contactId });
            return contact?.associated_companies?.companies;
          }
        );
      })
    );
  }

  associateContactToCompany(input: ContactCompanyAssociationDto[]): Observable<ContactCompanyAssociationDto[]> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      { "company_contact_association": input },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactCompanyAssociationDto[]>;
        return res.results;
      })
    );
  }

  updateAssociatedContactToCompanyRelationshipType(input: ContactCompanyAssociationDto): Observable<ContactCompanyAssociationDto> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      input,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactCompanyAssociationDto>;
        return res.results;
      })
    );
  }

  deleteAssociatedContactToCompany(input: ContactCompanyAssociationDto): Observable<ContactCompanyAssociationDto> {
    const requestURL = `${environment.apiURL}company-contacts/associations`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      input,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<ContactCompanyAssociationDto>;
        return res.results;
      })
    );
  }

}

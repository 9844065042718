import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
declare global {
  interface Window { electron: {isMac: boolean, isWindows: boolean, isLinux: boolean}; }
}
function isElectron() {
  // Renderer process
  if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
  }
  // Main process
  if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
  }
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
  }
  return false;
}
import * as SentryDesktop from "@sentry/electron/renderer";

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
const webIntegrations = [];

if(!isElectron()){
    webIntegrations.push(
        new BrowserTracing({
            tracingOrigins: ['localhost', environment.apiURL],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    )
    Sentry.init({
      enabled: environment.production,
      release: environment.appVersion,
      environment: environment?.name,
      dsn: environment.sentry_web,
      integrations: webIntegrations,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,
    });
} else {
  SentryDesktop.init({
    release: environment.appVersion,
    enabled: environment.production,
    environment: environment?.name,
    dsn:environment.sentry_desktop,
    tracesSampleRate: 0,
  });
}




if (environment.production) {
  enableProdMode();
  window.console.log = () => {  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

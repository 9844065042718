import { CreateSynthAgentDto, LinkSynthAgentDto, RecentSynthConversationsQueryParams, Synth, SynthConversation, SynthConversationInteractionsQuery, SynthInteraction, SynthRecentConversation } from "@/app/core/Models/synth";
import { environment } from "@/environments/environment";
import { Injectable } from "@angular/core";
import { last, map, Observable, Observer, pluck, tap } from "rxjs";
import { HttpService } from "../services/Networking/HttpService";
import { HttpRequestMethod } from "../services/Networking/HttpRequestMethod";
import { SuccessApiResponse } from "../services/Networking/ApiResponse";
import { CreateSynthConversationDto, CreateSynthInteraction, UpdateSynthConversationDto, UpdateSynthInteraction } from "../DTO/synthDto";
import { ISynthRepository } from "@/app/core/IRepositories/ISynthREpository";
import { getUploadEventProgress } from "@/app/Utilities/functions/getUploadEventProgress";

@Injectable({ providedIn: 'root' })
export class SynthRepository implements ISynthRepository {
    constructor(
        private httpService: HttpService
    ) { }

    getSynths(): Observable<Synth[]> {
        const requestURL = `${environment.apiURL}synth_trainer`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth[]>).results)
        );
    }

    createSynthConversation(data: CreateSynthConversationDto): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    updateSynthConversation(data: UpdateSynthConversationDto, synth_conversation_id: number | string): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation/${synth_conversation_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    getSynthConversation(): Observable<SynthConversation> {
        const requestURL = `${environment.apiURL}synth_trainer/recent_conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthConversation>).results)
        );
    }

    getSynthRecentConversations(query: RecentSynthConversationsQueryParams): Observable<SynthRecentConversation[]> {
        const requestURL = `${environment.apiURL}synth_trainer/recent_conversation`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            query
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => {
                return (item as SuccessApiResponse<SynthRecentConversation[]>).results
            })
        );
    }

    getSynthInteractionsByConversation({ conversationId, params }: SynthConversationInteractionsQuery): Observable<SynthInteraction[]> {
        const requestURL = `${environment.apiURL}synth_trainer/conversation/${conversationId}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.get,
            this.httpService.createHeader(),
            requestURL,
            null,
            false,
            params
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction[]>).results)
        );
    }

    createSynthInteraction(data: CreateSynthInteraction): Observable<SynthInteraction> {
        const requestURL = `${environment.apiURL}synth_trainer/interaction`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction>).results)
        );
    }

    updateSynthInteraction(data: UpdateSynthInteraction, synth_interaction_id: number | string): Observable<SynthInteraction> {
        const requestURL = `${environment.apiURL}synth_trainer/interaction/${synth_interaction_id}`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.put,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<SynthInteraction>).results)
        );
    }

    uploadKnowledgeBaseFiles(data: FormData, progress$: Observer<any>): Observable<string[]> {
        const requestURL = `${environment.apiURL}synth/agent/knowledge-base/upload`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false,
            null,
            true
        );
        return this.httpService.upload(options).pipe(
            tap((event: any) => getUploadEventProgress(event, progress$)),
            last(),
            pluck('body'),
            map((item) => {
                let res = item as SuccessApiResponse<any>;
                return res.results;
            })
        );
    }

    createSynthAgent(data: CreateSynthAgentDto): Observable<Synth> {
        const requestURL = `${environment.apiURL}synth/agent`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<Synth>).results)
        );
    }

    linkSynthAgent(data: LinkSynthAgentDto): Observable<any> {
        const requestURL = `${environment.apiURL}link_synth`;
        const options = this.httpService.createOptions(
            HttpRequestMethod.post,
            this.httpService.createHeader(),
            requestURL,
            data,
            false
        );
        return this.httpService.execute(options).pipe(
            pluck('results'),
            map((item) => (item as SuccessApiResponse<any>).results)
        );
    }
}

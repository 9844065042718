import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppTranslateService } from './Utilities/localization/translate.service';
import { PWAService } from './Utilities/pwa.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  isNewUpdate$ = this.pwaService.isNewUpdate$
  showNavigationIndicator = false;
  constructor(
    private router: Router,
    private pwaService: PWAService,
    private appTranslateService: AppTranslateService,
  ) { }

  ngOnInit(): void {
    this.pwaService.listenForUpdates();
    this.pwaService.getInstallPrompt();
    this.appTranslateService.init();
  }

  ngAfterViewInit(): void {
    // this.router.events.subscribe((event: any) => {
    //   switch (true) {
    //     case event instanceof NavigationStart: {
    //       this.showNavigationIndicator = true;
    //       break;
    //     }
    //     case event instanceof NavigationEnd:
    //     case event instanceof NavigationCancel:
    //     case event instanceof NavigationError: {
    //       this.showNavigationIndicator = false;
    //     }
    //   }
    // });
  }

  forceUpdate(): void {
    this.pwaService.forceUpdate()
  }
}

import { AssociatedCompanies, AssociatedContact, AssociatedContacts, ContactTasks, LookupContact } from '@/app/core/Models/contact';

import {
  GetContactsListAction,
  GetContactsListFailAction,
  GetContactsListSuccessAction,
  LookupContactsAction,
  LookupContactsSuccessAction,
  GetAssociateDealsToContactAction,
  GetAssociateDealsToContactSuccessAction,
  GetAssociateDealsToContactFailAction,
  GetAssociateContactToContactSuccessAction,
  GetContactTaskSuccessAction,
  GetAssociateContactToCompanySuccessAction,
  GetContactAttachmentsSuccess
} from './contacts.action';

import { createReducer, on } from '@ngrx/store';
import { Deal } from '@/app/core/Models/deal';

export interface ContactsState {
  contacts: {
    isLastpage: boolean,
    fetching: boolean,
    page?: number,
  }
  lookingUp: boolean,
  lookupContacts: LookupContact[],
  associatedDeals?: Deal[]
  associatedContacts?: AssociatedContacts,
  associatedTasks?: ContactTasks[],
  associatedCompanies?: AssociatedCompanies,
  associatedAttachments?: any[]
}

export const initContactsState: ContactsState = {
  contacts: {
    fetching: false,
    isLastpage: false,
  },
  lookupContacts: [],
  lookingUp: false
};

export const ContactsReducer = createReducer(
  initContactsState,

  on(
    GetContactsListAction,
    (state: ContactsState, { }) => {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          fetching: true
        }
      }
    }
  ),
  on(
    GetContactsListSuccessAction,
    (state: ContactsState, { payload: { contacts, params } }) => {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          page: params.page,
          fetching: false,
          isLastpage: contacts.length < params.page_size
        }
      }
    }
  ),
  on(
    GetContactsListFailAction,
    (state: ContactsState, { }) => {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          fetching: false
        }
      }
    }
  ),
  on(
    LookupContactsAction,
    (state: ContactsState, { }) => {
      return {
        ...state,
        lookingUp: true
      }
    }
  ),
  on(
    LookupContactsSuccessAction,
    (state: ContactsState, { payload: lookupContacts }) => {
      return {
        ...state,
        lookupContacts,
        lookingUp: false
      }
    }
  ),
  on(
    GetAssociateContactToContactSuccessAction,
    (state: ContactsState, { payload }) => {
      return {
        ...state,
        associatedContacts: payload
      }
    }
  ),
  on(
    GetContactTaskSuccessAction,
    (state: ContactsState, { payload }) => {
      return {
        ...state,
        associatedTasks: payload
      }
    }
  ),
  on(
    GetAssociateContactToCompanySuccessAction,
    (state: ContactsState, { payload }) => {
      return {
        ...state,
        associatedCompanies: payload
      }
    }
  ),
  on(
    GetContactAttachmentsSuccess,
    (state: ContactsState, { payload }) => {
      return {
        ...state,
        associatedAttachments: payload.attachments
      }
    }
  ),
);


import { CreatePipelineDto } from "@/app/Data/DTO/pipelineDto";

import { Pipeline, Stage } from "../Models/crm";

import { Observable } from "rxjs";

export abstract class IPipelineRepository {
    abstract getPipelines(): Observable<{ pipelines: Pipeline[], total_count: number }>;
    abstract getStagesByPipelineId(pipelineId: string): Observable<Stage[]>;
    abstract createPipeline(request: CreatePipelineDto): Observable<Pipeline>;
    abstract editPipeline(request: CreatePipelineDto, yobi_crm_pipeline_id: string): Observable<Pipeline>;
    abstract savePipelinesLocal(pipelines: Pipeline[]): void;
    abstract getLocalPipelines(): Observable<Pipeline[]>;
    abstract deletePipelineLocal(yobi_crm_pipeline_id: string): void
    abstract deletePipeline(yobi_crm_pipeline_id: string): Observable<Pipeline>
    abstract getStagesUnderPipelineLocal(yobi_crm_pipeline_id: string): Observable<Stage[] | undefined>
    abstract savePipelineStagesLocal(stages: Stage[]): void;
}
import { InteractionThread } from '@/app/core/Models/Interaction';
import { Task, TaskComment, TaskDetail, TaskLog, TaskStatus } from '@/app/core/Models/Task';
import { Tenant } from '@/app/core/Models/tenant';
import { TaskCommentDto } from '@/app/Data/DTO/TaskCommentDto';
import { TaskStatusDto } from '@/app/Data/DTO/TaskStatusDto';
import { ThreadMessagesQueryDto } from '@/app/Data/DTO/ThreadMessagesQueryDto';
import { TaskFormComponent } from '@/app/Ui/tasks/task-form/task-form.component';
import { TasksViewModel } from '@/app/Ui/ViewModels/tasksViewModel';
import { TaskViewModel } from '@/app/Ui/ViewModels/taskViewModel';
import { UsersViewModel } from '@/app/Ui/ViewModels/usersViewModel';
import {
  AfterViewInit,
  Component, ElementRef, Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  BehaviorSubject, filter,
  firstValueFrom,
  map,
  Observable,
  switchMap,
  take,
  tap
} from 'rxjs';
import { SubSink } from 'subsink';
import { ConfirmationDialogComponent } from '../../components/confirm-dialog/confirmation-dialog.component';
import { MessagesViewModel } from '../../ViewModels/messagesViewModel';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { Attachment, FileModel } from '@/app/core/Models/file';
import { fileTypeFromName } from '@/app/Utilities/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('activities_container') activities_container: ElementRef<HTMLElement>;
  sub = new SubSink();
  TaskStatus = TaskStatus;
  showEditOption = false;
  commentAdded = false;
  taskStatus: TaskDetail['status'] | undefined
  task$ = this.taskViewModel.task$.pipe(
    filter(item => !!item),
    tap((task) => {
      if (this.taskStatus && this.taskStatus !== task?.status) {
        this.updatingStatus$.next(false)
      }
      this.taskStatus = task?.status
      this.showEditOption = true;
    }));
  taskAttachments$ = this.task$.pipe(
    map(task => task?.attachments ?? []),
    map(attachments => attachments.map((attachment): Attachment => ({
      type: fileTypeFromName(attachment.filename),
      url: attachment.attachment_url,
      name: attachment.filename,
    })))
  )
  updatingStatus$ = new BehaviorSubject(false)

  mentions$ = this.usersViewModel.mentions$
  profile$: Observable<Tenant | undefined> = this.taskViewModel.profile$;
  private _commentsCount$ = new BehaviorSubject(3);
  activity$: Observable<{ comment?: TaskComment; log?: TaskLog }[]> =
    this._commentsCount$.pipe(
      switchMap((count: number) => {
        return this.taskViewModel.task$.pipe(
          map((item) => item?.comments!),
          map((items) => {
            return (items ?? [])
              .map((item) => {
                if ((item as TaskComment)['comment']) {
                  return { comment: item as TaskComment };
                } else {
                  return { log: item as TaskLog };
                }
              })
              .sort((a, b) => {
                let aa = a?.comment ?? a?.log;
                let bb = b?.comment ?? b?.log;
                let acreatedDate = new Date(aa.created_dt)
                let bcreatedDate = new Date(bb.created_dt)
                if (acreatedDate.getTime() < bcreatedDate.getTime()) {
                  return -1;
                }
                if (acreatedDate.getTime() > bcreatedDate.getTime()) {
                  return 1;
                }
                return 0;
              });
          }),
          tap(() => {
            if (this.commentAdded) {
              setTimeout(() => {
                this.activities_container.nativeElement.scrollTo({
                  top: this.activities_container.nativeElement.scrollHeight,
                  behavior: 'smooth',
                });
                this.activities_container.nativeElement.scrollTop = this.activities_container.nativeElement?.scrollHeight
              }, 250)
            }
          }),
        );
      })
    );

  selectedTab = 'comments';
  editableCommentId$ = this.taskViewModel.editableCommentId$;
  localization = this.localiztionViewModel.localization
  constructor(
    private localiztionViewModel: LocalizationViewModel,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TaskDetailsComponent>,
    private taskViewModel: TaskViewModel,
    private tasksViewModel: TasksViewModel,
    private usersViewModel: UsersViewModel,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private messagesViewModel: MessagesViewModel,
    @Inject(MAT_DIALOG_DATA) public data: { taskId: number, yobi_crm_company_id?: any, contact_id?: any }
  ) { }

  ngOnInit(): void {
    this.taskViewModel.loadTaskDetail(this.data.taskId);
    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { task_id: this.data.taskId } }).toString()
    this.location.go(url);
  }



  updateStatusToDone(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.done,
    });
  }

  updateStatusToArchived(taskId: number): void {
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.archived,
    });
  }

  updateStatusToInProgress(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.inProgress,
    });
  }

  updateStatusToOpen(taskId: number): void {
    this.updatingStatus$.next(true)
    this.taskViewModel.updateTaskStatus({
      taskId,
      status: TaskStatus.open,
    });
  }

  openThread(interaction: InteractionThread) {
    this.messagesViewModel.openConversationThread({
      threadId: (interaction.yobi_contact_id ?? interaction.channel_id)!,
      unClassified: !interaction.yobi_contact_id
    })
    this.close()
  }

  getPayload(task?: TaskDetail) {
    let payload: any = { taskId: task?.task_id };
    if (this.data.yobi_crm_company_id) {
      payload = { ...payload, yobi_crm_company_id: this.data?.yobi_crm_company_id };
    }
    if (this.data.contact_id) {
      payload = { ...payload, contact_id: this.data?.contact_id };
    }
    return payload;
  }

  async editTask() {
    const task = await firstValueFrom(this.task$);
    this.dialog.open(TaskFormComponent, {
      disableClose: true,
      height: 'fit-content',
      maxHeight: '90vh',
      width: '700px',
      maxWidth: '90vw',
      panelClass: 'custom-dialog-container',
      data: this.getPayload(task)
    }).afterClosed()
      .subscribe((res: { type: 'created' | 'updated' | 'archive' }) => {
        switch (res?.type) {
          case 'archive':
            this.confirmArchive();
            //this.updateStatusToArchived();
            break;
          case 'updated':
            this.tasksViewModel.getAllTasks();
            break;
        }
      });
    this.close()
  }


  confirmArchive(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: this.localization.confirm.archive_task,
        confirm: this.localization.labels.confirm,
      }
    }).afterClosed().subscribe(async (res) => {
      if (res) {
        const profile = await firstValueFrom(this.profile$);
        let request: TaskStatusDto = {
          status: TaskStatus.archived,
          taskId: this.data.taskId,
          userId: profile?.userId,
        };
        this.taskViewModel.updateTaskStatus(request);
        this.dialogRef.close(true);
      }
    });
  }

  enableEditForComment(event: { commentId: number }): void {
    this.taskViewModel.enableEditForComment(event);
  }

  deleteComment(data: { commentId: number }, taskId: number): void {
    this.taskViewModel.deleteComment(data.commentId, taskId);
  }

  resendComment(activity: { comment?: TaskComment }, taskId: number): void {
    this.taskViewModel.addComment({
      taskId,
      comment: activity.comment!.comment,
      commentId: activity.comment!.comment_id
    })
  }

  async commentEdit(
    data: { comment: string; commentId: number },
    taskId: number
  ): Promise<any> {
    const request: TaskCommentDto = { ...data, taskId };
    this.taskViewModel.editComment(request);
  }

  async addTaskComment(
    data: { comment: string },
    taskId: number
  ): Promise<any> {
    const request: TaskCommentDto = { ...data, taskId };
    this.taskViewModel.addComment(request);
    this.commentAdded = true;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: {} }).toString()
    this.location.go(url);
  }

}

import { RecentInteraction } from '@/app/core/Models/Interaction';
import { Contact } from '@/app/core/Models/contact';
import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';


@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  _name = ''
  @Input() letterSpacing = true;
  @Input() backgroundColor = '#c1c1c1';
  @Input() fontSize = 14;
  @Input() fontColor = '#ffff';
  @Input('url') set _url(url: null | string | undefined) {
    this.urlError = false;
    this.url = url ?? '';
  };
  @Input('data') set data(value: any) {
    const name = value
      ? (value.sender_name
        ? value.sender_name
        : (value as Contact).isContact
          ? (value.displayName || `${value.firstName || ''} ${value.lastName || ''}`.trim())
          : (value as RecentInteraction).interaction && (value as RecentInteraction).channel_type !== 'phone'
            ? `${value.given_name || ''} ${value.family_name || ''}`.trim()
            : ''
      )
      : '';
    name && this.setText(name);
  }
  @Input('name') set name(name: any) {
    name && isNaN(name) ? this.setText(name) : this.text = '';
  }
  @Input() isRoom: boolean | undefined;
  @Input() isRoomPrivate: boolean | undefined;

  url = ''
  text = '';
  urlError = false

  setText(name: string): void {
    this._name = name
    // Remove "(Tenant n)" and special characters
    const cleanedName = name.replace(/\(Tenant (\d)+\)/, '').replace(/[-\/:-@\[-\`{-~]/g, '');

    // Split the cleaned name into words
    const words = cleanedName.split(/\s+/).filter(item => !!item.length);

    // If there are more than one word, use them; otherwise, split the single word into characters
    const parts = words.length > 1 ? words : words[0]?.split('') ?? [];

    // Take the first two words and capitalize their first letters
    const initials = parts.slice(0, 2).map(word => word.charAt(0).toUpperCase());

    // Set the result as the 'text' property
    this.text = initials.join('');
  }

  // setText(name: string): void {
  //   let tab = name
  //     .replace(/\(Tenant (\d)+\)/, '')
  //     .replace(/[-\/:-@\[-\`{-~]/g, '')
  //     .split(/\s{1,}/)
  //     .filter((item) => !!item.length);
  //   tab = tab.length == 1
  //     ? tab[0].split('')
  //     : tab;
  //   this.text = tab
  //     .map(t => t.charAt(0).toUpperCase())
  //     .slice(0, 2)
  //     .join('')
  // }
}


import { createSelector } from '@ngrx/store';
import { AppState } from '@/app/State/AppState';
import { CompaniesState } from './companies.reducer';

const companiesState = (state: AppState) => state.companies;

export const selectActiveCompanyId = createSelector(
  companiesState,
  (state) => state.active?.companyId
);

export const selectActiveCompanyState = createSelector(
  companiesState,
  (state) => state.active
);

export const selectFetchingCompany = createSelector(
  companiesState,
  (state) => !!state.active?.fetching
);

export const selectCompaniesState = createSelector(
  companiesState,
  (state: CompaniesState) => state.companies
);

export const associatedContacts = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedContacts
);

export const associatedCompanies = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedCompanies
);

export const associatedNotes = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedNotes
);

export const associatedTasks = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedTasks
);

export const associatedCustomFields = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedCustomFields
);

export const associatedDeals = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedDeals
);


export const associatedAttachments = createSelector(
  companiesState,
  (state: CompaniesState) => state.associatedAttachments
);
import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgModule } from '@angular/core';
@Pipe({
  name: 'shortDate',
})
export class ShortDatePipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      const date = +new Date(value);
      const seconds = Math.round((+new Date() - date) / 1000);
      return null ? null
        : seconds < intervals.day ? formatDate(date, 'hh:mm', 'en-US')
          : seconds < intervals.day * 2 ? '1d'
            : seconds < intervals.week ? formatDate(date, 'EEE', 'en-US')
              : seconds < intervals.year ? formatDate(date, 'MMM d', 'en-US')
                : formatDate(date, 'M/d/y', 'en-US');
    }
    return '';
  }
}

@NgModule({
  declarations: [ShortDatePipe],
  exports: [ShortDatePipe],
  imports: [],
  providers: [],
})
export class ShortDateModule {
  constructor() { }
}

<div class="flex flex-col gap-1">
  <label
        *ngIf="label"
        [for]="label"
        class="font-semibold text-pale"
        [ngClass]="{'icon-label': iconLabel ? true : false }">
        <svg-icon
            *ngIf="iconLabel"
            class="icon-color"
            [src]="iconLabel"
            [svgStyle]="{ height: '20px', width: '20px' }">
        </svg-icon>
        {{ label }}
    </label>
  <div
    [class]="defaultClasses"
    [ngStyle]="ngStyle"
    [ngClass]="{invalid: invalid || !isValid}">
    <ng-content select="[prefix]"></ng-content>
    <ng-content select="[input]"></ng-content>
    <ng-content select="[select]"></ng-content>
    <ng-content select="[textarea]"></ng-content>
    <ng-content select="[suffix]"></ng-content>
    <span 
        *ngIf="maxLength"
        class="text-orange-400 whitespace-nowrap m-2 mr-2.5">
        {{currentLength || 0}}/<span>{{maxLength}}</span>
    </span>
  </div>
</div>

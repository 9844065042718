import { RecentSynthConversationsQueryParams, Synth, SynthConversation, SynthConversationInteractionsQuery, SynthInteraction, SynthRecentConversation } from "@/app/core/Models/synth";
import { Tenant } from "@/app/core/Models/tenant";
import { CreateSynthConversationDto, CreateSynthInteraction, UpdateSynthConversationDto } from "@/app/Data/DTO/synthDto";
import { createAction, props, union } from "@ngrx/store";

/* ------------------------------- GET_SYNTHS ------------------------------- */
export const GET_SYNTHS = '[SYNTH] GET_SYNTHS';
export const GetSynthsAction = createAction(
    GET_SYNTHS
);
export const GET_SYNTHS_SUCCESS = '[SYNTH] GET_SYNTHS_SUCCESS';
export const GetSynthsSuccessAction = createAction(
    GET_SYNTHS_SUCCESS,
    props<{ payload: Synth[] }>()
);
export const GET_SYNTHS_FAIL = '[SYNTH] GET_SYNTHS_FAIL';
export const GetSynthsFailAction = createAction(
    GET_SYNTHS_FAIL
);

/* ------------------------ CREATE_SYNTH_INTERACTION ----------------------- */
export const CREATE_SYNTH_INTERACTION = '[SYNTH] CREATE_SYNTH_INTERACTION';
export const CreateSynthInteractionAction = createAction(
    CREATE_SYNTH_INTERACTION,
    props<{ payload: { data: CreateSynthInteraction } }>()
);
export const CREATE_SYNTH_INTERACTION_SUCCESS = '[SYNTH] CREATE_SYNTH_INTERACTION_SUCCESS';
export const CreateSynthInteractionSuccessAction = createAction(
    CREATE_SYNTH_INTERACTION_SUCCESS,
    props<{ payload: { interaction: SynthInteraction } }>()
);
export const CREATE_SYNTH_INTERACTION_FAIL = '[SYNTH] CREATE_SYNTH_INTERACTION_FAIL';
export const CreateSynthInteractionFailction = createAction(
    CREATE_SYNTH_INTERACTION_FAIL,
    props<{ payload: { data: CreateSynthInteraction } }>()
);

/* ------------------------ CREATE_SYNTH_CONVERSATION ----------------------- */
export const CREATE_SYNTH_CONVERSATION = '[SYNTH] CREATE_SYNTH_CONVERSATION';
export const CreateSynthConversationAction = createAction(
    CREATE_SYNTH_CONVERSATION,
    props<{ payload: { data: CreateSynthConversationDto } }>()
);
export const CREATE_SYNTH_CONVERSATION_SUCCESS = '[SYNTH] CREATE_SYNTH_CONVERSATION_SUCCESS';
export const CreateSynthConversationSuccessAction = createAction(
    CREATE_SYNTH_CONVERSATION_SUCCESS,
    props<{ payload: { data: SynthConversation, profile: Tenant | undefined } }>()
);
export const CREATE_SYNTH_CONVERSATION_FAIL = '[SYNTH] CREATE_SYNTH_CONVERSATION_FAIL';
export const CreateSynthConversationFailction = createAction(
    CREATE_SYNTH_CONVERSATION_FAIL,
);

/* ------------------------ UPDATE_SYNTH_CONVERSATION ----------------------- */
export const UPDATE_SYNTH_CONVERSATION = '[SYNTH] UPDATE_SYNTH_CONVERSATION';
export const UpdateSynthConversationAction = createAction(
    UPDATE_SYNTH_CONVERSATION,
    props<{ payload: { data: UpdateSynthConversationDto, synth_conversation_id: number | string } }>()
);
export const UPDATE_SYNTH_CONVERSATION_SUCCESS = '[SYNTH] UPDATE_SYNTH_CONVERSATION_SUCCESS';
export const UpdateSynthConversationSuccessAction = createAction(
    UPDATE_SYNTH_CONVERSATION_SUCCESS,
    props<{ payload: { conversation: SynthConversation } }>()
);
export const UPDATE_SYNTH_CONVERSATION_FAIL = '[SYNTH] UPDATE_SYNTH_CONVERSATION_FAIL';
export const UpdateSynthConversationFailAction = createAction(
    UPDATE_SYNTH_CONVERSATION_FAIL,
);

/* --------------------- GET_SYNTH_RECENT_CONVERSATIONS --------------------- */
export const GET_SYNTH_RECENT_CONVERSATIONS = '[SYNTH] GET_SYNTH_RECENT_CONVERSATIONS';
export const GetSynthRecentConversationsAction = createAction(
    GET_SYNTH_RECENT_CONVERSATIONS,
    props<{ payload: { query: RecentSynthConversationsQueryParams } }>()
);
export const GET_SYNTH_RECENT_CONVERSATIONS_SUCCESS = '[SYNTH] GET_SYNTH_RECENT_CONVERSATIONS_SUCCESS';
export const GetSynthRecentConversationsSuccessAction = createAction(
    GET_SYNTH_RECENT_CONVERSATIONS_SUCCESS,
    props<{ payload: { conversations: SynthRecentConversation[], query: RecentSynthConversationsQueryParams } }>()
);
export const GET_SYNTH_RECENT_CONVERSATIONS_FAIL = '[SYNTH] GET_SYNTH_RECENT_CONVERSATIONS_FAIL';
export const GetSynthRecentConversationsFailction = createAction(
    GET_SYNTH_RECENT_CONVERSATIONS_FAIL,
);

/* --------------------- GET_SYNTH_CONVERSATION_INTERACTIONS --------------------- */
export const GET_SYNTH_CONVERSATION_INTERACTIONS = '[SYNTH] GET_SYNTH_CONVERSATION_INTERACTIONS';
export const GetSynthConversationInteractionsAction = createAction(
    GET_SYNTH_CONVERSATION_INTERACTIONS,
    props<{ payload: { query: SynthConversationInteractionsQuery } }>()
);
export const GET_SYNTH_CONVERSATION_INTERACTIONS_SUCCESS = '[SYNTH] GET_SYNTH_CONVERSATION_INTERACTIONS_SUCCESS';
export const GetSynthConversationInteractionsSuccessAction = createAction(
    GET_SYNTH_CONVERSATION_INTERACTIONS_SUCCESS,
    props<{ payload: { interactions: SynthInteraction[], query: SynthConversationInteractionsQuery } }>()
);
export const GET_SYNTH_CONVERSATION_INTERACTIONS_FAIL = '[SYNTH] GET_SYNTH_CONVERSATION_INTERACTIONS_FAIL';
export const GetSynthConversationInteractionsFailAction = createAction(
    GET_SYNTH_CONVERSATION_INTERACTIONS_FAIL,
);

/* --------------------- HANDLE_SYNTH_INTERACTION --------------------- */
export const HANDLE_SYNTH_INTERACTION = '[SYNTH] HANDLE_SYNTH_INTERACTION';
export const HandleSynthInteractionAction = createAction(
    HANDLE_SYNTH_INTERACTION,
    props<{ payload: { interaction: SynthInteraction } }>()
);


const all = union({
    GetSynthsAction,
    GetSynthsSuccessAction,
    CreateSynthConversationAction,
    CreateSynthConversationSuccessAction,
    CreateSynthConversationFailction,
    UpdateSynthConversationAction,
    GetSynthRecentConversationsAction,
    GetSynthRecentConversationsSuccessAction,
    GetSynthRecentConversationsFailction,
    GetSynthConversationInteractionsAction,
    CreateSynthInteractionAction
})

export type SynthActions = typeof all;
export class Stage {
    created_dt: string;
    description: string;
    stage_name: string;
    updated_dt: string;
    yobi_crm_stage_id: string
    stage_label?: string;
    stage_order: number;
}

export class Pipeline {
    created_dt: string;
    description: string;
    pipeline_name: string;
    updated_dt: string;
    yobi_crm_journey_id: string
    yobi_crm_pipeline_id: string
    stages: Stage[]
}

<div
  class="flex flex-col gap-2 rounded-xl p-4 pb-6 relative"
  cdkTrapFocus
>
  <div class="flex items-center justify-between mb-2">
    <button
      #channelsSelector
      cdk
      [matMenuTriggerFor]="menu"
      class="flex items-center font-medium h-10 px-3 rounded w-fit hover:secondary-bg-color focus:ring outline-0"
    >
      <span
        *ngIf="!voiceClientViewModel.primaryPhoneChannel"
        class="text-warning font-medium"
      >Select phone number</span>
      <span
        *ngIf="voiceClientViewModel.primaryPhoneChannel"
        class="text-primary font-medium"
      >{{localization.labels.primary | translate}}&nbsp;-&nbsp;</span>
      {{ voiceClientViewModel.primaryPhoneChannel?.channel_value | phone }}
    </button>
    <svg-icon
      (click)="close()"
      class="text-pale hover:text-warning cursor-pointer"
      [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
      src="assets/icons/new-set/close.svg"
    ></svg-icon>
  </div>

  <app-phone-contact-input
    [isMac]="isMac"
    class="mt-2"
    [invalid]="inputController.invalid"
    [inputController]="inputController"
    [filtredContacts]="(lookupContacts$ | async) || []"
    (addPhoneNumber)="addPhoneNumber($event)"
    (addContactChannel)="addContactChannel($event)"
    (countryCodeSelected)="countryCodeSelected($event)"
  ></app-phone-contact-input>

  <p class="text-xl font-medium text-center mt-4 w-3/4 truncate mx-auto">
    {{ ((receiver$ | async)?.displayName ?? '') | phone }}
  </p>

  <div
    class="flex flex-col justify-center overflow-hidden transition-all ease-in flex-auto my-3"
    [style.height]="this.showDialer ? '250px' : '0'"
  >
    <app-dialer-pad (onDigit)="onDigit($event)"></app-dialer-pad>
  </div>

  <div class="flex flex-row justify-around items-center">
    <!-- ------------------------------ settings ------------------------------- -->
    <button
      style="border: 1px solid rgba(0 0 0/0.04)"
      class="flex items-center justify-center h-12 w-12 text-dark secondary-bg-color hover:light-bg-color rounded-full focus:ring outline-0"
      [matTooltip]="localization.terms.settings| translate"
      [dropDownPanel]="settingsRef"
      [panelClass]="['custom-dialog-panel']"
    >
      <svg-icon
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/settings.svg"
      ></svg-icon>
    </button>
    <!-- ------------------------------ make call ------------------------------ -->
    <button
      class="flex items-center p-2 px-4 bg-primary text-white rounded-full gap-1 h-12 focus:ring outline-0"
      [matTooltip]="'labels.make_a_call' | translate"
      (click)="makeCall()"
    >
      <svg-icon
        *ngIf="!isCalling"
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/phone.svg"
      ></svg-icon>
      <mat-spinner
        *ngIf="isCalling"
        class="svg-stroke-white"
        diameter="18"
      ></mat-spinner>
      <span class="text-base leading-tight">
        Call
      </span>
    </button>
    <!-- ------------------------------- dialpad ------------------------------- -->
    <button
      style="border: 1px solid rgba(0 0 0/0.04)"
      class="flex items-center justify-center h-12 w-12 text-dark secondary-bg-color hover:light-bg-color rounded-full focus:ring outline-0"
      [matTooltip]="'labels.dialer_pad' | translate"
      (click)="openDialPad()"
    >
      <svg-icon
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/dialer/dialpad.svg"
      ></svg-icon>
    </button>

  </div>
</div>

<mat-menu #menu="matMenu">
  <button
    *ngFor="let channel of (phoneChannels$ | async) || []"
    style="height: 4rem;"
    (click)="updateCurrentChannel(channel)"
    mat-menu-item
  >
    <div class="flex items-center gap-3 text-mild">
      <svg-icon
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/phone.svg"
      ></svg-icon>
      <div class="flex flex-col gap-1 min-w-0 overflow-hidden">
        <span class="text-base font-medium leading-tight truncate">{{ channel.channel_name | phone }}</span>
        <span class="text-sm text-pale leading-tight">{{ channel.channel_value | phone }}</span>
      </div>
      <span
        class="text-sm mt-auto text-primary cursor-pointer hover:underline ml-auto"
        [cdkCopyToClipboard]="channel.channel_value"
        (cdkCopyToClipboardCopied)="phoneCopiedToClipbaord()"
        (click)="$event.stopPropagation()"
      >Copy</span>
    </div>
  </button>
  <button
    [routerLink]="'/main/settings/channels'"
    (click)="close()"
    mat-menu-item
  >
    <div class="flex items-center gap-3 text-mild">
      <svg-icon
        [svgStyle]="{ 'height.px': 20, 'width.px': 20 }"
        src="assets/icons/new-set/add.svg"
      ></svg-icon>
      <span class="text-base font-medium leading-tight">Add phone number</span>
    </div>
  </button>
</mat-menu>

<ng-template #settingsRef>
  <app-dialer-audio-settings></app-dialer-audio-settings>
</ng-template>
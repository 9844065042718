import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filenameFormatter'
})
export class FilenameFormatterPipe implements PipeTransform {

  transform(value: any): string {
    if (!value) return value;

    const extensionIndex = value.lastIndexOf('.');
    const extension = extensionIndex !== -1 ? value.substring(extensionIndex) : '';
    const nameWithoutExtension = extensionIndex !== -1 ? value.substring(0, extensionIndex) : value;

    const firstLine = nameWithoutExtension.substring(0, 16);
    const remainingName = nameWithoutExtension.substring(16);

    if (remainingName.length <= 7) {
      return `${firstLine}\n${remainingName}${extension}`;
    }

    const middlePart = remainingName.slice(0, 7) + '...';
    const lastPart = remainingName.slice(-3) + extension;

    const formattedName = `${firstLine}\n${middlePart}${lastPart}`;
    return formattedName;
  }

}

@NgModule({
  declarations: [FilenameFormatterPipe],
  exports: [FilenameFormatterPipe],
  imports: [],
  providers: [],
})
export class FilenameFormatterModule{
  constructor() { }
}


import { Roles } from './Roles';
import { InternalChannel } from '@/app/core/Models/Channel';

export class Tenant {
  account: TenantAccount;
  active: boolean;
  email: string;
  firstName: string;
  businessName: string;
  onboarding_questions_answered: boolean;
  VOICE_ONBOARDING_COMPLETED: boolean;
  JOTFORM_SUBMISSION_ID?: string;
  avatar: string;
  lastName: string;
  verified: boolean;
  userId: number;
  tenantId: number;
  tenantLogo: string;
  tenant_created_dt: Date;
  plan_type: string;
  doNotDisturb: boolean;
  onBoarding: {
    addchannel: boolean;
    addcontacts: boolean;
    addusers: boolean;
    buyphone: boolean;
  };
  username: string;
  supportContactId?: number;
  channels: InternalChannel[];
  notificationsSettings: NotificationSettings;
  roles: Roles[];
  get displayName(): string {
    return this.firstName ? `${this.firstName}` : `${this.lastName}`;
  };
  get fullName(): string {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
  };
  user_permissions: Array<{ permission_name: string, permission_value: string }>
}

export type NotificationSettings = {
  comment: boolean;
  missed_call: boolean;
  analytics: boolean;
  notification_id: number;
  task: boolean;
  tenant_id: number;
  transcription: boolean;
  user_id: number;
  voice_mail: boolean;
};

export type TenantAccount = {
  business_name: string;

  email: {
    channel_value?: string;
    validation: boolean;
  };

  phone: {
    channel_value?: string;
    validation: boolean;
  };

  is_updated: boolean;
};


export class TenantCustomFields {
  custom_field_key_id: number
  tenant_id: number
  custom_field_name: string
  created_dt: string
  updated_dt: string
  is_deleted: boolean
}

export class IndustryTenantTypes {
  industry_type: string
  tenant_company_industry_id: number
  description: string
  tenant_id: string
}
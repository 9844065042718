import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationForm, CreateSynthAgentDto, IdentityForm, ITranscriptions, Synth, SynthAgent, SynthConversation, SynthRecentConversation } from '@/app/core/Models/synth';
import { CreateSynthConversationDto, CreateSynthInteraction } from '@/app/Data/DTO/synthDto';
import { AppState } from '@/app/State/AppState';
import { CreateSynthAgentAction, CreateSynthConversationAction, CreateSynthInteractionAction, GetSynthAction, GetSynthConversationInteractionsAction, GetSynthRecentConversationsAction, GetSynthsAction, SelectSynthAction, SelectSynthConversationIdAction, UpdateSynthAgentAction, UpdateSynthConversationAction, TrascriptSynthAction } from '@/app/State/synth/synth.action';
import { selectedConversationId, selectedSynth, selectRecentSynthsConversationsState, selectSelectedSynthConversation, selectSynthsList, firstCallTranscriptions } from '@/app/State/synth/synth.selector';

import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { ISynthRepository } from '@/app/core/IRepositories/ISynthREpository';
import { dynamicKeySort } from '@/app/Utilities/helpers';

@Injectable({
  providedIn: 'root'
})
export class SynthViewModel {

  activeSynth$ = this.store.select(selectedSynth);

  selectedLocalSynthTemplateEngine$ = this.activeSynth$.pipe(
    filter((synth) => !!synth?.synth_id),
    switchMap((selectedSynth) => this.getLocalSynthTemplateEngine(selectedSynth?.synth_id as any))
  );

  selectedLocalSynth$ = this.activeSynth$.pipe(
    filter((synth) => !!synth?.synth_id),
    switchMap((selectedSynth) => this.getLocalSynth(selectedSynth?.synth_id as any))
  );

  synthsListLocal$ = this.synthRepository.getLocalSynths().pipe(
    startWith([]), distinctUntilChanged(),
    map((items) => {
      return items.sort(dynamicKeySort('synth_created_dt_timestamps', 'desc'));
    }));

  synthsList$ = this.store.select(selectSynthsList);
  synthConversationId$ = this.store.select(selectedConversationId);
  recentSynthsConversationsState$ = this.store.select(selectRecentSynthsConversationsState).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedSynthConversation$ = this.store.select(selectSelectedSynthConversation).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedRecentSynthConversation$ = this.selectedSynthConversation$.pipe(
    distinctUntilChanged((a, b) => a.conversationId === b.conversationId),
    switchMap(state => this.recentSynthsConversationsState$.pipe(
      map(recents => recents.result.find(r => r.synth_conversation_id === state.conversationId)!)
    ))
  )
  synthThreadInteractions$ = this.selectedSynthConversation$.pipe(
    map(x => [...x.interactions].sort((a, b) => new Date(b.created_dt).getTime() - new Date(a.created_dt).getTime()))
  );

  synthTranscriptions$ = this.store.select(firstCallTranscriptions);

  synthTools = [
    {
      "synth_tool_id": 2,
      "tool_key": "conversation_recall",
      "tool_name": "Conversation Memory",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 5,
      "tool_key": "auto_tasks",
      "tool_name": "notify_tool",
      "tool_type": "discrete"
    },
    {
      "synth_tool_id": 1,
      "tool_key": "backend",
      "tool_name": "transfer call",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 3,
      "tool_key": "backend",
      "tool_name": "lookup knowledgebase",
      "tool_type": "voice and discrete"
    }
  ];

  voices = [
    {
      voice_name: 'Myra',
      synth_voice_id: 2,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/myra.mp3'
    },
    {
      voice_name: 'Grace',
      synth_voice_id: 3,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.mp3'
    },
    {
      voice_name: 'Zuri',
      synth_voice_id: 4,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3'
    },
    {
      voice_name: 'Anna',
      synth_voice_id: 6,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.mp3'
    },
    {
      voice_name: 'Lucas',
      synth_voice_id: 7,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3'
    }
  ];

  presets = [
    {
      name: 'Dental Receptionist',
      description: 'Meet Kate: Your 24/7 Dental AI Receptionist',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/kate-03_optimized_100.png',
      link: 'https://receptionist.yobi.app/',
      id: 1,
      synth_name: 'Kate',
      role: 'Dental Receptionist',
      company_name: 'Yobi Smiles',
      personality: "Be Concise. Be direct like a receptionist.Favor short questions, without explanations. Lead the conversation. When asking questions, ask one short question at a time unless it's for a family booking save time by asking at once. Use a colloquial way of referring to the date (like Friday, January 14th, or Tuesday, January 12th, 2024 at 8am). When listing items or steps, use narrative listing and sequential prose",
      greetings: "Hi, this is Kate from Yobi Smiles, how can I help you?",
      synth_voice_id: 6,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.mp3',
      file_name: 'kate-03_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: ` Respond to queries about services offered by our dental practice. If you don't have enough information to answer a query offer to create a note for our staff to reach out to the caller and help answer their question. You must handle scheduling appointments by offering to create a note for our staff to reach out to the caller and help them  schedule an appointment. Before creating any note you must collect these information from the caller:
          • Full Name
          • Preferred Contact Method (Email/Phone)
          • Preferred Contact Time
          • Injusry
      `,
      tasks: [
        'Caller is requesting to schedule an appointment',
        'Caller is requesting staff help answering a question'
      ]
    },
    {
      name: 'Podcast Host',
      description: 'Meet Zara: Your Energetic And Curious AI Podcast Host',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/zara-02_optimized_100.png',
      link: 'https://receptionist.yobi.app/podcast-2',
      id: 2,
      synth_name: 'Zara',
      role: 'Podcast Host',
      company_name: 'FoundersPod',
      personality: "Be concise, direct, and favor short questions without explanations. Lead the conversation and ask one short question at a time. Use a colloquial way of referring to dates. Adapt and guess to understand transcripts that may contain errors, but avoid mentioning 'transcription error'. Stay in character and ensure fluid dialogue. Be brief. Spell out all phone numbers. Your goal is to conduct an interview that listeners will love, swiftly collect guest information, and in case of an emergency, let them know you've informed the staff immediately. You're working as a podcast host with only a phone available. Keep work environment details to yourself. Do not try to verify the guest's identity. Ask the caller one question at a time",
      greetings: "Hi this is Zara , your host on FoundersPod, who do I have the pleasure of speaking with?",
      synth_voice_id: 4,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3',
      file_name: 'zara-02_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: `Collect guest you are interviewing information Focus on creating engaging content for listeners Lead interviews with enthusiasm and curiosity Maintain character and ensure smooth dialogue
      `,
      tasks: [
        'An emergency situation'
      ]
    },
    {
      name: 'Recruiter',
      description: 'Meet Andrew, Your AI Recruiting Assistant And Interview Coach',
      icon: 'https://yobi-public-files.s3.us-west-2.amazonaws.com/newandrew-05_optimized_100.png',
      link: 'https://receptionist.yobi.app/recruiter',
      id: 3,
      synth_name: 'Andrew',
      role: 'Recruiter',
      company_name: 'Yobi Talent',
      personality: "Your main objective is to interview candidates for various positions. Be concise, direct, and lead the conversation. Ask short, domain-specific questions one at a time. Use a colloquial way of referring to dates. End the interview after 15 questions and ask if the candidate has any questions. If you don't have answers, inform the candidate you'll follow up soon. If asked about acceptance, state that results will be sent via email. Collect the candidate's full name, contact information (phone or email), and assess their skills through questioning. Do not try to verify their identity. Spell out all phone numbers instead of using numeric characters. Adapt and guess to understand transcripts that may contain errors, without mentioning 'transcription error'. Stay in character and ensure fluid dialogue. Be brief in your responses.",
      greetings: "Hi, this is Andrew, your interviewer, can it's nice to meet you. What position are you applying for?",
      synth_voice_id: 7,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3',
      file_name: 'newandrew-05_optimized_100.png',
      type: 'image',
      isUsed: false,
      instructions: `Collect the candidate's full name, contact information (phone or email), and assess their skills through questioning Conduct interviews asking brief and direct domain-specific questions, one question at a time. Limit 
        interviews to 15 questions Assess skills through targeted questioning If asked a question you are unable to 
        answer, inform the candidate you'll follow up soon.
      `,
      tasks: [
        "Assistant does not have enough information to answer candidate's question"
      ]
    }
  ];

  steps: '1' | '2' | '3' = '1';

  stepsDetails = [
    {
      step: 1,
      name: 'Identity',
      isActive: true,
      isDone: false
    },
    {
      step: 2,
      name: 'Configuration',
      isActive: false,
      isDone: false
    },
    {
      step: 3,
      name: 'Test/Link',
      isActive: false,
      isDone: false
    }
  ];

  uploadStashFiles: Array<{
    filename: string,
    raw_filename: string,
    size: number,
    type: string
  }> = [];

  collections: {
    upload: string[],
    cancel: string[]
  } = {
      upload: [],
      cancel: []
    }

  synthPermissions = {
    'create_synth_agent': false,
    'configure_synth_agent': false,
    'train_synth_agent': false,
    'test_synth_agent': false
  }

  mode: 'create' | 'edit';
  selectedSynth$ = this.store.select(selectedSynth);
  identityDetails?: SynthAgent;
  newCreatedSynthAgent: SynthAgent;
  configurationForm?: ConfigurationForm;
  newCreatedSynthAPI = new BehaviorSubject<{ synth_id: number, tenant_id: string, user_id: string, channel_value: any, endpoint_name: string } | null>(null);
  defaultStep = false;
  attachmentChanged = false;
  embedSynthId: number;
  breadcrumb$ = new BehaviorSubject<'main' | 'configuration' | 'testing' | 'training' | 'action' | null>(null);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private synthRepository: ISynthRepository
  ) { }

  selectSynth(synth: Synth): void {
    this.store.dispatch(SelectSynthAction({ payload: { synth } }));
  }

  getSynths(search: string) {
    this.store.dispatch(GetSynthsAction({ payload: { page: 1, page_size: 20, search } }));
  }

  async fetchRecentSynthConversations(synth_id: number, train: string, page?: number) {
    const state = await firstValueFrom(this.recentSynthsConversationsState$)
    this.store.dispatch(GetSynthRecentConversationsAction({
      payload: {
        query: {
          page: page ?? state.currentPage + 1,
          page_size: state.pageSize,
          synth_id,
          train
        }
      }
    }))
  }

  setConversationId(conversationId: number) {
    this.store.dispatch(SelectSynthConversationIdAction({ payload: { conversationId } }));
  }

  selectSynthConversation(conversationId: number) {
    this.fetchSynthConversationInteractions(conversationId);
  }

  async fetchSynthConversationInteractions(conversationId: number) {
    const state = await firstValueFrom(this.selectedSynthConversation$);
    console.log('clg fetchSynthConversationInteractions', state.conversationId, conversationId);
    this.store.dispatch(GetSynthConversationInteractionsAction({
      payload: {
        query: {
          conversationId: state.conversationId as number,
          params: {
            page: 1,
            page_size: state.pageSize
          }
        }
      }
    }))
  }

  newSynthConversation(synth_id: string | number, type: 'voice' | 'text', train: boolean) {
    this.store.dispatch(CreateSynthConversationAction({ payload: { data: { synth_id, title: 'Unnamed', type, train } } }))
  }

  sendMessage(data: CreateSynthInteraction) {
    this.store.dispatch(CreateSynthInteractionAction({ payload: { data } }))
  }

  setConversationTitle(synth_conversation_id: number, title: string) {
    this.store.dispatch(UpdateSynthConversationAction({
      payload: { data: { title, is_active: false, is_record_disabled: false }, synth_conversation_id: synth_conversation_id }
    }))
  }

  createSynthAgent(): void {
    this.router.navigate(['main/workshop/create']);
  }

  editSynthAgent(id: number) {
    this.router.navigate([`main/synth-trainer/edit/${id}`]);
  }

  getSynthAgentScript(synth_id: number) {
    return this.synthRepository.getSynthAgentScript(synth_id);
  }

  createToken(retell_agent_id: string, conversation_id: number) {
    return this.synthRepository.createCallSynthAgent(retell_agent_id, conversation_id);
  }

  createSynthAgentAction(input: CreateSynthAgentDto): void {
    this.store.dispatch(CreateSynthAgentAction({ payload: input }));
  }

  updateSynthAgentAction(input: CreateSynthAgentDto, synth_id: number): void {
    this.store.dispatch(UpdateSynthAgentAction({ payload: { data: input, synth_id } }))
  }

  getSynthDetails(synth_id: number): void {
    this.store.dispatch(GetSynthAction({ payload: synth_id }));
  }

  getSynthDetailsRaw(synth_id: number) {
    return this.synthRepository.getSynthDetails(synth_id);
  }

  createSynthConversation(data: CreateSynthConversationDto) {
    return this.synthRepository.createSynthConversation(data);
  }

  getLocalSynth(synth_id: number) {
    return this.synthRepository.getLocalSynth(synth_id);
  }

  getLocalSynthTemplateEngine(synth_id: number) {
    return this.synthRepository.getLocalSynthTemplateEngine(synth_id);
  }

  getAudioOpt() {
    return [
      'play', // Play/pause playback
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'mute', // Toggle mute
      // 'volume', // Volume control
      'captions', // Toggle captions
      'pip', // Picture-in-picture (currently Safari only)
      'airplay', // Airplay (currently Safari only)
      'fullscreen', // Toggle fullscreen
    ];
  }

  generateTranscriptsFeedback(synth_id: number, transcripts: string) {
    return this.synthRepository.generateTranscriptsFeedback(synth_id, transcripts);
  }

  resetHandler(resetMode: boolean): void {
    this.uploadStashFiles = [];
    this.configurationForm = undefined;
    this.identityDetails = undefined;
    this.newCreatedSynthAgent = undefined as any;
    this.presets = this.presets.map((item) => { return { ...item, isUsed: false }; });
    this.newCreatedSynthAPI.next(null);
    if (resetMode) this.mode = undefined as any;
  }

  showFirstTanscription(input: ITranscriptions): void {
    this.store.dispatch(TrascriptSynthAction({ payload: { transcriptions: input } }));
  }

  get yobiOneAgentId() {
    return 'agent_c196ca3d399bfe3cdf3204fc9f';
  }

}

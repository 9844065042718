import { ChannelType } from "@/app/core/Models/ChannelTypes";
import { LookupContact } from "@/app/core/Models/contact";
import { AppState } from "@/app/State/AppState";
import { LookupContactsAction } from "@/app/State/contacts/contacts.action";
import { selectLookingupContacts, selectLookupContacts } from "@/app/State/contacts/contacts.selector";
import { checkPhoneValidity, getFormattedPhoneNumber } from "@/app/Utilities/helpers";
import { SnackbarService } from "@/app/Utilities/snackbar/snackbar.service";
import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { CountryCode } from "libphonenumber-js";
import { BehaviorSubject } from 'rxjs';

declare type ReceiverModel = {
    phoneNumber: string,
    displayName?: string | null,
    contact_id?: number | null
    avatar?: string | null,
}

@Injectable()
export class DialerViewModel {
    receiver$ = new BehaviorSubject<ReceiverModel | null>(null)
    countryCode: CountryCode = 'US';
    inputController = new FormControl('');
    lookingupContacts$ = this.store.select(selectLookingupContacts);
    lookupContacts$ = this.store.select(selectLookupContacts);

    constructor(
        private store: Store<AppState>,
        private snackService: SnackbarService,
    ) { }

    lookupContacts(keyword: string): void {
        this.store.dispatch(LookupContactsAction({ payload: { channel_type: ChannelType.Phone, keyword } }));
    }

    addPhoneNumber(value = ''): void {
        value = value || this.inputController.value
        if (value === this.receiver$.value?.phoneNumber) return
        try {
            if (!checkPhoneValidity(value, this.countryCode)) {
                this.snackService.openAlert({
                    message: "Phone number is not valid!",
                    type: 'failure',
                    duration: 2500
                });
                return;
            }
            const phoneNumber = getFormattedPhoneNumber(value, this.countryCode)
            this.receiver$.next({ phoneNumber, contact_id: null, displayName: null, avatar: null });
            this.inputController.patchValue(phoneNumber);
            return
        } catch {
            this.snackService.openAlert({
                message: "Phone number is not valid!",
                type: 'failure',
                duration: 2500
            });
        }
    }

    resetReceiver() {
        this.receiver$.next(null);
    }

    addContact({ avatar, family_name, given_name, channel_value, contact_id }: LookupContact) {
        if (!channel_value) {
            this.snackService.openAlert({
                message: "This contact doesn't have a phone number",
                type: 'failure',
                duration: 3000
            });
            return;
        }
        if (!checkPhoneValidity(channel_value, this.countryCode)) {
            this.snackService.openAlert({
                message: "Contact phone number is not valid!",
                type: 'failure',
                duration: 2500
            });
            return;
        }
        const phoneNumber = getFormattedPhoneNumber(channel_value, this.countryCode)
        this.receiver$.next({ displayName: `${given_name} ${family_name}`.trim(), phoneNumber, contact_id, avatar });
        this.inputController.patchValue(phoneNumber)
    }

    clearState() {
        this.receiver$.next({ phoneNumber: '' });
        this.inputController.patchValue('')
    }
}

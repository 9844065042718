import { Injectable } from '@angular/core';

import { DatabaseService } from '../services/Database/database.service';
import { HttpService } from '@/app/Data/services/Networking/HttpService';

import { CreateDealDto, UpdateDealRequest } from '../DTO/dealDto';
import { Deal, DealDetail, DealsQueryParams } from '@/app/core/Models/deal';
import { SuccessApiResponse } from '@/app/Data/services/Networking/ApiResponse';
import { HttpRequestMethod } from '@/app/Data/services/Networking/HttpRequestMethod';

import { environment } from '@/environments/environment';

import { from, map, Observable, pluck } from 'rxjs';
import { liveQuery } from 'dexie';

@Injectable()
export class DealRepository {
  constructor(
    private httpService: HttpService,
    private databaseService: DatabaseService,
  ) { }

  getDeals(request: DealsQueryParams): Observable<{ deals: Deal[], total_count: number }> {
    const requestURL = `${environment.apiURL}deal?page=${request.page}&page_size=${request.page_size}&search=${request.search ?? ''}&column=${request.column}&direction=${request.direction}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<{ deals: Deal[], total_count: number }>;
        return res.results;
      })
    );
  }

  createDeal(request: CreateDealDto): Observable<Deal> {
    const requestURL = `${environment.apiURL}deal`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.post,
      this.httpService.createHeader(),
      requestURL,
      request,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Deal>;
        return res.results;
      })
    );
  }

  updateDeal(request: CreateDealDto): Observable<Deal> {
    const requestURL = `${environment.apiURL}deal/${request.yobi_crm_deal_id}`;
    const payload = { ...request };
    delete payload['yobi_crm_deal_id'];
    const options = this.httpService.createOptions(
      HttpRequestMethod.put,
      this.httpService.createHeader(),
      requestURL,
      payload,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Deal>;
        return res.results;
      })
    );
  }

  deleteDeal(dealIds: string[]): Observable<Deal> {
    const requestURL = `${environment.apiURL}deal`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.delete,
      this.httpService.createHeader(),
      requestURL,
      { "yobi_crm_deal_id": dealIds },
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<Deal>;
        return res.results;
      })
    );
  }

  getDealById(yobi_crm_deals_id: string): Observable<DealDetail> {
    const requestURL = `${environment.apiURL}yobi_crm/deals/${yobi_crm_deals_id}`;
    const options = this.httpService.createOptions(
      HttpRequestMethod.get,
      this.httpService.createHeader(),
      requestURL,
      null,
      false
    );
    return this.httpService.execute(options).pipe(
      pluck('results'),
      map((item) => {
        let res = item as SuccessApiResponse<DealDetail>;
        return res.results;
      })
    );
  }


  /* ---------------------------------- LOCAL --------------------------------- */

  saveDeals(deals: Deal[], params: DealsQueryParams): void {
    this.databaseService
      .transaction('rw!', this.databaseService.deals, async () => {
        params.page == 1 && await this.databaseService.deals.clear();
        await this.databaseService.deals.bulkPut(deals);
      })
      .catch((error) => {
        console.error('Transaction Failed: ', error);
      });
  }

  getLocalDeals(): Observable<Deal[]> {
    return from(
      liveQuery(() => {
        this.databaseService.deals.mapToClass(Deal);
        return this.databaseService.deals.toArray();
      })
    );
  }

  getLocalDeal(dealId: string): Observable<Deal | undefined> {
    return from(
      liveQuery(() => {
        return this.databaseService.transaction(
          'r',
          this.databaseService.deals,
          async () => {
            this.databaseService.deals.mapToClass(Deal);
            return this.databaseService.deals.get(dealId as unknown as string)
          }
        );
      })
    );
  }

  saveDeal(deal: Deal): void {
    this.databaseService.deals.put(deal);
  }

  deleteLocalDeal(dealId: string): void {
    this.databaseService.deals.delete(dealId)
  }


}
import { IdentityForm, SynthAgent, SynthConversation, SynthRecentConversation } from '@/app/core/Models/synth';
import { CreateSynthInteraction } from '@/app/Data/DTO/synthDto';
import { AppState } from '@/app/State/AppState';
import { CreateSynthConversationAction, CreateSynthInteractionAction, GetSynthConversationInteractionsAction, GetSynthRecentConversationsAction, UpdateSynthConversationAction } from '@/app/State/synth/synth.action';
import { selectRecentSynthsConversationsState, selectSelectedSynthConversation, selectSynthsList } from '@/app/State/synth/synth.selector';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, firstValueFrom, map, shareReplay, switchMap } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class SynthViewModel {
  synthsList$ = this.store.select(selectSynthsList)
  recentSynthsConversationsState$ = this.store.select(selectRecentSynthsConversationsState).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedSynthConversation$ = this.store.select(selectSelectedSynthConversation).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  )
  selectedRecentSynthConversation$ = this.selectedSynthConversation$.pipe(
    distinctUntilChanged((a, b) => a.conversationId === b.conversationId),
    switchMap(state => this.recentSynthsConversationsState$.pipe(
      map(recents => recents.result.find(r => r.synth_conversation_id === state.conversationId)!)
    ))
  )
  synthThreadInteractions$ = this.selectedSynthConversation$.pipe(
    map(x => [...x.interactions].sort((a, b) => new Date(b.created_dt).getTime() - new Date(a.created_dt).getTime()))
  )

  identityDetails: IdentityForm;


  synthTools = [
    {
      "synth_tool_id": 2,
      "tool_key": "conversation_recall",
      "tool_name": "Conversation Memory",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 5,
      "tool_key": "auto_tasks",
      "tool_name": "notify_tool",
      "tool_type": "discrete"
    },
    {
      "synth_tool_id": 1,
      "tool_key": "backend",
      "tool_name": "transfer call",
      "tool_type": "voice"
    },
    {
      "synth_tool_id": 3,
      "tool_key": "backend",
      "tool_name": "lookup knowledgebase",
      "tool_type": "voice and discrete"
    }
  ];

  voices = [
    {
      voice_name: 'Myra',
      synth_voice_id: 2,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/myra.mp3'
    },
    {
      voice_name: 'Grace',
      synth_voice_id: 3,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.mp3'
    },
    {
      voice_name: 'Zuri',
      synth_voice_id: 4,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3'
    },
    {
      voice_name: 'Anna',
      synth_voice_id: 6,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.mp3'
    },
    {
      voice_name: 'Lucas',
      synth_voice_id: 7,
      voice_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3'
    }
  ];

  presets = [
    {
      name: 'Dental Receptionist',
      description: 'Meet Kate: Your 24/7 Dental AI Receptionist',
      icon: 'https://yobi.app/wp-content/uploads/2024/08/kate-03.png',
      link: 'https://receptionist.yobi.app/',
      id: 1
    },
    {
      name: 'Podcast Host',
      description: 'Meet Zara: Your Energetic And Curious AI Podcast Host',
      icon: 'https://yobi.app/wp-content/uploads/2024/08/zara-02.png',
      link: 'https://receptionist.yobi.app/podcast-2',
      id: 2
    },
    {
      name: 'Recruiter',
      description: 'Meet Andrew, Your AI-Powered Recruiting Assistant And Interview Coach',
      icon: 'https://yobi.app/wp-content/uploads/2024/08/newandrew-05.png',
      link: 'https://receptionist.yobi.app/recruiter',
      id: 3
    }
  ];

  stepsDetails = [
    {
      step: 1,
      name: 'Identity',
      isActive: true,
      isDone: false
    },
    {
      step: 2,
      name: 'Configuration',
      isActive: false,
      isDone: false
    },
    {
      step: 3,
      name: 'Test/Link',
      isActive: false,
      isDone: false
    }
  ];

  uploadStashFiles: Array<{
    filename: string,
    raw_filename: string,
    size: number,
    type: string
  }> = [];

  collections: {
    upload: string[],
    cancel: string[]
  } = {
      upload: [],
      cancel: []
    }

  newCreatedSynthAgent: SynthAgent;
  newCreatedSynthAPI = new BehaviorSubject<{ synth_id: string, tenant_id: string, user_id: string, channel_value: any, endpoint_name: string } | null>(null);

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  async fetchRecentSynthConversations(page?: number) {
    const state = await firstValueFrom(this.recentSynthsConversationsState$)
    this.store.dispatch(GetSynthRecentConversationsAction({
      payload: {
        query: {
          page: page ?? state.currentPage + 1,
          page_size: state.pageSize
        }
      }
    }))
  }


  selectSynthConversation(conversationId: number) {
    this.fetchSynthConversationInteractions(conversationId);
  }

  async fetchSynthConversationInteractions(conversationId: number) {
    const state = await firstValueFrom(this.selectedSynthConversation$)
    this.store.dispatch(GetSynthConversationInteractionsAction({
      payload: {
        query: {
          conversationId,
          params: {
            page: state.conversationId === conversationId ? state.currentPage + 1 : 1,
            page_size: state.pageSize
          }
        }
      }
    }))
  }

  newSynthConversation(synth_id: string | number) {
    this.store.dispatch(CreateSynthConversationAction({ payload: { data: { synth_id, title: 'Unnamed' } } }))
  }

  sendMessage(data: CreateSynthInteraction) {
    this.store.dispatch(CreateSynthInteractionAction({ payload: { data } }))
  }

  setConversationTitle({ synth_conversation_id }: SynthRecentConversation, title: string) {
    this.store.dispatch(UpdateSynthConversationAction({
      payload: { data: { title, is_active: false, is_record_disabled: false }, synth_conversation_id: +synth_conversation_id! } // TODO
    }))
  }

  createSynthAgent(): void {
    this.router.navigate(['main/synth-trainer/c']);
  }
}

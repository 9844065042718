import { Synth, SynthConversation, SynthInteraction, SynthRecentConversation } from '@/app/core/Models/synth';
import { createReducer, on } from '@ngrx/store';
import { CreateSynthConversationSuccessAction, CreateSynthInteractionAction, CreateSynthInteractionFailction, CreateSynthInteractionSuccessAction, GetSynthConversationInteractionsAction, GetSynthConversationInteractionsFailAction, GetSynthConversationInteractionsSuccessAction, GetSynthRecentConversationsAction, GetSynthRecentConversationsFailction, GetSynthRecentConversationsSuccessAction, GetSynthsSuccessAction, HandleSynthInteractionAction, UpdateSynthConversationSuccessAction } from './synth.action';

export interface SynthState {
    synths: Synth[];
    recentConversations: {
        pageSize: number,
        currentPage: number,
        result: SynthRecentConversation[],
        fetching: boolean,
        isLastPage: boolean
    }
    selectedConversation: {
        conversationId?: number,
        fetching?: boolean,
        isLastPage?: boolean,
        currentPage: number,
        interactions: SynthInteraction[]
        pageSize: number,
    }
}

export const initSynthState: SynthState = {
    synths: [],
    recentConversations: {
        pageSize: 20,
        currentPage: 0,
        result: [],
        fetching: false,
        isLastPage: false,
    },
    selectedConversation: {
        currentPage: 0,
        interactions: [],
        pageSize: 20
    }
};

export const synthReducer = createReducer(
    initSynthState,
    on(GetSynthsSuccessAction, (state: SynthState, { payload }) => {
        return {
            ...state,
            synths: payload
        };
    }),
    on(GetSynthRecentConversationsAction, (state: SynthState, { payload }) => {
        return {
            ...state,
            recentConversations: {
                ...state.recentConversations,
                fetching: true,
            }
        };
    }),
    on(UpdateSynthConversationSuccessAction, (state: SynthState, { payload: { conversation: { synth_conversation_id, title, is_active, is_record_disabled } } }) => {
        return {
            ...state,
            recentConversations: {
                ...state.recentConversations,
                result: [
                    ...state.recentConversations.result.map(r => r.synth_conversation_id === synth_conversation_id
                        ? { ...r, is_active, is_record_disabled, title }
                        : r
                    )
                ]
            }
        };
    }),
    on(GetSynthRecentConversationsSuccessAction, (state: SynthState, { payload: { conversations, query } }) => {
        return {
            ...state,
            recentConversations: {
                ...state.recentConversations,
                fetching: false,
                isLastPage: conversations.length < query.page_size,
                result: query.page === 1 ? [...conversations] : [...state.recentConversations.result, ...conversations]
            }
        };
    }),
    on(GetSynthRecentConversationsFailction, (state: SynthState) => {
        return {
            ...state,
            recentConversations: {
                ...state.recentConversations,
                fetching: false
            }
        };
    }),


    on(GetSynthConversationInteractionsAction, (state: SynthState, { payload: { query: { conversationId, params: { page } } } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation,
                conversationId,
                currentPage: state.selectedConversation?.currentPage ?? 1,
                fetching: true,
                isLastPage: false,
                interactions: page === 1 ? [] : state.selectedConversation?.interactions ?? []
            }
        };
    }),
    on(GetSynthConversationInteractionsSuccessAction, (state: SynthState, { payload: { query: { params }, interactions } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation!,
                currentPage: params.page,
                fetching: false,
                isLastPage: interactions.length < params.page_size,
                interactions: [
                    ...state.selectedConversation!.interactions, 
                    ...([...interactions].reverse())
                ].filter(i => !i.is_system && !!i.message.trim())
            }
        };
    }),
    on(GetSynthConversationInteractionsFailAction, (state: SynthState, { }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation!,
                fetching: false,
            }
        };
    }),

    on(CreateSynthConversationSuccessAction, (state: SynthState, { payload: { data, profile } }) => {
        return {
            ...state,
            recentConversations: {
                ...state.recentConversations,
                result: [{
                    avatar: profile?.avatar ?? '',
                    family_name: profile?.lastName ?? '',
                    given_name: profile?.firstName ?? '',
                    message: '',
                    title: data.title,
                    username: profile?.username ?? '',
                    synth_conversation_id: data.synth_conversation_id,
                    synth_display_name: data.synth_display_name
                }, ...state.recentConversations.result]
            }
        };
    }),

    on(CreateSynthInteractionAction, (state: SynthState, { payload: { data } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation,
                interactions: [
                    data.SynthInteraction,
                    ...state.selectedConversation.interactions,
                ].filter(i => !i.is_system && !!i.message.trim())
            }
        };
    }),
    on(CreateSynthInteractionSuccessAction, (state: SynthState, { payload: { interaction } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation,
                interactions: [
                    interaction,
                    ...state.selectedConversation.interactions.filter(i => !(i.pending && i.message === i.message) && i.synth_interaction_id !== interaction.synth_interaction_id),
                ].filter(i => !i.is_system && !!i.message.trim())
            }
        };
    }),
    on(CreateSynthInteractionFailction, (state: SynthState, { payload: { data } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation,
                interactions: [
                    ...state.selectedConversation.interactions.map(interaction => interaction.pending && interaction.message === data.message
                        ? { ...interaction, failed: true, pending: false }
                        : interaction
                    ),
                ].filter(i => !i.is_system && !!i.message.trim())
            }
        };
    }),
    on(HandleSynthInteractionAction, (state: SynthState, { payload: { interaction } }) => {
        return {
            ...state,
            selectedConversation: {
                ...state.selectedConversation,
                interactions: [
                    { ...interaction, latest_synth_message: interaction.is_synth },
                    ...state.selectedConversation.interactions.filter(i => !(i.pending && i.message === i.message) && i.synth_interaction_id !== interaction.synth_interaction_id),
                ].filter(i => !i.is_system && !!i.message.trim())
            }
        };
    }),
);

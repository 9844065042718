import { VoiceClientViewModel } from '@/app/Ui/ViewModels/VoiceClientViewModel';
import { TenantViewModel } from '@/app/Ui/ViewModels/tenantViewModel';
import { PlatformService } from '@/app/Utilities/platform/platform.service';
import { InternalChannel } from '@/app/core/Models/Channel';
import { ChannelType } from '@/app/core/Models/ChannelTypes';
import { LookupContact } from '@/app/core/Models/contact';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CountryCode } from 'libphonenumber-js';
import { Observable, debounceTime, distinctUntilChanged, filter, map, startWith, tap, throttleTime } from 'rxjs';
import { SubSink } from 'subsink';
import { LocalizationViewModel } from '../../ViewModels/localizationViewModel';
import { DialerRequest, DialerService } from '../dialer.service';
import { DialerViewModel } from '../dialerViewModel';
import { MyOverlayRef } from '@/app/Utilities/Dialog/dialog.service';
import { PhoneContactInputComponent } from '../../components/phone-contact-input/phone-contact-input.component';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialerViewModel]
})
export class DialerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('channelsSelector') channelsSelector: ElementRef<HTMLButtonElement>
  @ViewChild(PhoneContactInputComponent, { read: ElementRef }) phoneContactInput: ElementRef<any>

  isCalling = false
  sub = new SubSink();
  isMac = this.PlatformService.isMac;
  showDialer = false;
  localization = this.localizationViewModel.localization;
  phoneChannels$: Observable<InternalChannel[]>
  receiver$ = this.dialerViewModel.receiver$;
  inputController = this.dialerViewModel.inputController;
  lookingupContacts$ = this.dialerViewModel.lookingupContacts$
  lookupContacts$ = this.dialerViewModel.lookupContacts$

  constructor(
    public voiceClientViewModel: VoiceClientViewModel,
    private tenantViewModel: TenantViewModel,
    private dialerViewModel: DialerViewModel,
    private dialerService: DialerService,
    private localizationViewModel: LocalizationViewModel,
    private PlatformService: PlatformService,
    private dialogRef: MyOverlayRef,
  ) { }

  ngOnInit(): void {
    this.phoneChannels$ = this.tenantViewModel.internalChannels$.pipe(
      map(channels => channels.filter((item) => item.channel_type == ChannelType.Phone)),
      tap((channels) => {
        const channel = this.voiceClientViewModel.getPrimaryChannel(channels);
        !this.voiceClientViewModel.primaryPhoneChannel && channel && this.voiceClientViewModel.setPrimaryChannel(channel);
      })
    );

    this.sub.sink = this.dialerService.onRequest$.pipe(
      filter((data): data is DialerRequest => !!data),
      tap(({ phoneNumber, avatar, displayName }) => {
        this.dialerService.onRequest$.next(null);
        this.dialerViewModel.receiver$.next({ avatar, displayName, phoneNumber: phoneNumber, contact_id: null });
        this.dialerViewModel.inputController.patchValue(phoneNumber)
      })
    ).subscribe();

    this.sub.sink = this.inputController.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      throttleTime(500, undefined, { leading: true, trailing: true }),
      tap((value: string) => {
        this.inputController.setErrors(null);
        value && value !== this.receiver$.value?.phoneNumber && this.dialerViewModel.resetReceiver()
        this.dialerViewModel.lookupContacts(value)
      })
    ).subscribe()
  }

  ngAfterViewInit(): void {
    this.receiver$.value
      ? this.channelsSelector.nativeElement.focus()
      : ((this.phoneContactInput.nativeElement as HTMLElement).querySelector('input') as HTMLInputElement).focus()
  }
  addPhoneNumber(value: string) {
    this.dialerViewModel.addPhoneNumber(value)
  }

  addContactChannel(contact: LookupContact) {
    this.dialerViewModel.addContact(contact)
  }

  countryCodeSelected(countryCode: CountryCode) {
    this.dialerViewModel.countryCode = countryCode
  }

  openDialPad(): void {
    this.showDialer = !this.showDialer;
  }

  updateCurrentChannel(channel: InternalChannel): void {
    this.voiceClientViewModel.setPrimaryChannel(channel);
  }

  onDigit(digit: any): void {
    this.dialerViewModel.inputController.setValue(
      this.dialerViewModel.inputController.value.concat(digit)
    );
  }

  phoneCopiedToClipbaord() {
    this.tenantViewModel.copiedToClipbaord()
  }

  async makeCall() {
    if (!this.dialerViewModel.receiver$.value?.phoneNumber && this.inputController.value) {
      this.dialerViewModel.addPhoneNumber(this.inputController.value);
    }
    if (
      this.dialerViewModel.receiver$.value?.phoneNumber
      && this.voiceClientViewModel.primaryPhoneChannel?.channel_value
    ) {
      this.isCalling = true
      this.voiceClientViewModel.makeCall(
        this.dialerViewModel.receiver$.value.phoneNumber,
        this.voiceClientViewModel.primaryPhoneChannel.channel_value,
        this.dialerViewModel.receiver$.value.displayName ?? '',
        this.dialerViewModel.receiver$.value.avatar ?? '',
      );
      return
    }
    if (!this.dialerViewModel.receiver$.value?.phoneNumber) {
      this.inputController.setErrors({ emptyNumber: true });
    }
  }

  ngOnDestroy(): void {
    this.dialerViewModel.resetReceiver();
    this.sub.unsubscribe();
  }

  close() {
    this.dialogRef.close({ type: 'Close' })
  }
}
